import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchSmiFile = createAsyncThunk(
  "sandSmiFile",
  async (type, data) => {
    console.log(data);
    const response = await axios({
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `https://ivsmi.tideflo.work/backend/upload_${
        type === "kor" ? "kor" : "eng"
      }`,
      data: data,
    })
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
    const result = response.data;
    console.log(result.data);

    return result;
  }
);

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const sandSlice = createSlice({
  name: "sandSmiFile",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSmiFile.pending.type]: (state) => {
      state = {
        loading: true,
        data: null,
        error: null,
      };
    },
    [fetchSmiFile.fulfilled.type]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [fetchSmiFile.rejected.type]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
  },
});

export default sandSlice.reducer;
