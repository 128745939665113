import React from "react";
import ReactLoading from "react-loading";

export default function Loading() {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#333333",
        opacity: 0.9,
      }}
    >
      <ReactLoading
        type={"spokes"}
        color={"#002262"}
        height={"10%"}
        width={"10%"}
      />
    </div>
  );
}
