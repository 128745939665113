import { useState } from "react";
import styled from "styled-components";

export default function Alert({
  onClick,
  title,
  okButtonClick,
  cancelButtonClick,
  contents,
  isOpen,
  isAlert,
}) {
  return (
    <>
      {isOpen === true ? (
        <>
          <Container>
            <CardContainer>
              <div
                style={{
                  height: "142px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text>{contents}</Text>
              </div>
              <ButtonContainer>
                {isAlert ? (
                  <OkButton style={{ width: "100%" }} onClick={onClick}>
                    확인
                  </OkButton>
                ) : (
                  <>
                    <CancelButton>취소</CancelButton>
                    <OkButton>진행</OkButton>
                  </>
                )}
              </ButtonContainer>
            </CardContainer>
          </Container>
        </>
      ) : null}
    </>
  );
}

const Container = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`;

const CardContainer = styled.div`
  margin-top: 50px;
  width: 388px;
  height: 200px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const Text = styled.h1`
  font-size: 16px;
  line-height: 23.17px;
  font-weight: 400;
  color: #333333;
  text-align: center;
`;
const ButtonContainer = styled.div`
  height: 58px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  width: 100%;
`;
const OkButton = styled.div`
  background: #002262;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  line-height: 26.06px;
  :hover {
    cursor: pointer;
  }
`;
const CancelButton = styled.div`
  background: #aeaeae;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  line-height: 26.06px;
  :hover {
    cursor: pointer;
  }
`;
