import React, { useState } from "react";
import styled from "styled-components";
import logo from "../shared/logo.jpg";
import Button from "./Button";
import Input from "./Input";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Alert from "./Alert";

export default function LoginBox() {
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const alertContents = "로그인이 완료되었습니다.";
  const onLoginClick = async () => {
    if (id === "") {
      return alert("아이디를 입력해주세요.");
    }
    if (password === "") {
      return alert("비밀번호를 입력해주세요.");
    }

    const data = JSON.stringify({
      id: id,
      pass: password,
    });
    const response = await axios({
      method: "post",
      // url: "http://subtitle.insightvessel.io/backend/login",
      url: "https://ivsmi.tideflo.work/backend/login",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((res) => {
        if (res.data.message === "id") {
          return alert("아이디를 확인해주세요.");
        }
        if (res.data.message === "pass") {
          return alert("비밀번호를 확인해주세요.");
        }
        if (res.data.message === "success") {
          alert("로그인이 완료되었습니다.");
          sessionStorage.setItem("Login", true);
          return navigate("/main");
        }
      })
      .catch((e) => console.log(e));

    return response;
  };

  return (
    <>
      <Container>
        <Logo src={logo} />
        <Input
          placeholder="LOGIN"
          value={id}
          onChange={(e) => setId(e.target.value)}
          onKeyPress={(e) => {
            if (e.key == "Enter") {
              onLoginClick();
            }
          }}
        />
        <Input
          placeholder="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={(e) => {
            if (e.key == "Enter") {
              onLoginClick();
            }
          }}
        />
        <div style={{ marginBottom: "40px" }} />
        <Button
          name="로그인"
          size="big"
          onClick={() => onLoginClick()}
          width="381"
        />
      </Container>
      {/* <Alert
        contents={alertContents}
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        isAlert={true}
      /> */}
    </>
  );
}

const Container = styled.div`
  background-color: #ffffff;
  width: 445px;
  height: 452px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
`;
const Logo = styled.img`
  width: 145.91px;
  height: 56px;
  margin-bottom: 56px;
`;
