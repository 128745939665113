import styled from "styled-components";
import login from "../shared/login.png";
import passwords from "../shared/password.png";

export default function Input({
  placeholder,
  type,
  onChange,
  value,
  onKeyPress,
}) {
  return (
    <Container>
      <IconContainer>
        {placeholder === "LOGIN" ? (
          <Icon src={login} />
        ) : (
          <Icon src={passwords} />
        )}
      </IconContainer>
      <Inputs
        placeholder={placeholder}
        type={type === "password" ? "password" : null}
        onChange={onChange}
        value={value}
        onKeyPress={onKeyPress}
      />
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 0;
`;
const Inputs = styled.input`
  width: 379px;
  height: 46px;
  padding: 0;
  border: 1px solid #aeaeae;
  border-radius: 3px;
  ::placeholder {
    font-size: 14px;
  }
  text-indent: 45px;
`;

const Icon = styled.img`
  width: 13.56px;
  height: 16px;
`;
const IconContainer = styled.i`
  position: absolute;
  margin-left: 16px;
`;
