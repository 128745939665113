import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchGetSmiList = createAsyncThunk("smiList", async () => {
  const response = await axios({
    method: "POST",
    url: "https://ivsmi.tideflo.work/backend/list",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => console.log("목록 불러오기 \n", res.data))
    .catch((error) => console.log(error));

  const result = response.data.data;

  return result;
});

const initialState = {
  smiList: {
    loading: false,
    data: null,
    error: null,
  },
};

const smiListSlice = createSlice({
  name: "smiList",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchGetSmiList.pending.type]: (state) => {
      state.smiList = { loading: true, data: null, error: null };
    },
    [fetchGetSmiList.fulfilled.type]: (state, action) => {
      console.log(action.payload);
      state.smiList.data = action.payload;
      state.smiList.loading = false;
    },
    [fetchGetSmiList.rejected.type]: (state, action) => {
      state.smiList.error = action.error;
      state.smiList.loading = false;
    },
  },
});

export default smiListSlice.reducer;
