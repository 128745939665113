import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Main from "./pages/Main";
import SmiParser from "./pages/SmiParser";
export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/main" element={<Main />}>
          <Route path="/main/:id" element={<Main />} />
        </Route>
        <Route path="/smi" element={<SmiParser />}>
          <Route path="/smi/:id" element={<SmiParser />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
