import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  engData: null,
  korData: null,
  korFileName: null,
  engFileName: null,
  sameList: [],
};

const tableSlice = createSlice({
  name: "tableSlice",
  initialState,
  reducers: {
    setFileName: (state, action) => {
      action.payload.type === "kor"
        ? (state.korFileName = action.payload.data)
        : (state.engFileName = action.payload.data);
    },
    updateTableData: (state, action) => {
      const type = action.payload.type;
      if (type === "kor") {
        state.korData = action.payload.data;
      } else {
        state.engData = action.payload.data;
      }
    },
    sameListUpdate: (state, action) => {
      state.sameList = action.payload;
    },
    // updataTableTextData: (state, action) => {
    //   const type = action.payload.type;
    //   if (type === "kor") {
    //     const newData = [...state.korData];

    //     action.payload.data.forEach(([row, column, oldValue, newValue]) => {
    //       newData[row][column] = newValue;
    //     });

    //     return {
    //       ...state,
    //       korData: newData,
    //     };
    //   } else {
    //     const newData = [...state.engData];

    //     action.payload.data.forEach(([row, column, oldValue, newValue]) => {
    //       newData[row][column] = newValue;
    //     });

    //     state.engData =
    //   }
    // },
  },
});

export default tableSlice.reducer;
export const {
  loadTableData,
  updateTableData,
  setFileName,
  updataTableTextData,
  sameListUpdate,
} = tableSlice.actions;
