import styled from "styled-components";
import back from "../shared/back.png";
import save from "../shared/save.png";

export default function Button({ name, image, size, color, onClick, width }) {
  return (
    <Container size={size} color={color} onClick={onClick} width={width}>
      {image === "back" ? <BackIcon src={back} /> : null}
      <Title>{name}</Title>
      {image === "save" ? <SaveIcon src={save} /> : null}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  background: ${(props) => props.color || "#002262"};
  width: ${(props) => props.width}px;
  height: 45px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  :hover {
    cursor: pointer;
  }
`;
const Title = styled.p`
  margin: 0;
  padding: 0;
  color: #ffffff;
  font-size: 16px;
  line-height: 23px;
  font-weight: 500;
  user-select: none;
`;
const BackIcon = styled.img`
  width: 19.2px;
  height: 16px;
  margin-right: 10px;
`;
const SaveIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 10px;
`;
