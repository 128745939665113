import styled from "styled-components";
import TableBody from "./TableBody";

export default function Table({ data, onChange, checked, onCheckedElement }) {
  return (
    <Styled>
      {data === null ? (
        <NonData>작업 중인 데이터가 없습니다.</NonData>
      ) : (
        <table>
          <thead>
            <Tr>
              <Checkbox>
                <input type="checkbox" onChange={onChange} checked={checked} />
              </Checkbox>
              <No>NO</No>
              <Title>제목</Title>
              <Date>작성일</Date>
              <Language>언어</Language>
              <Complete>진행도</Complete>
            </Tr>
          </thead>
          <tbody>
            <TableBody data={data} onCheckedElement={onCheckedElement} />
          </tbody>
        </table>
      )}
    </Styled>
  );
}
const Styled = styled.div`
  table {
    border: 1px solid #d9d9d9;
    border-collapse: collapse;
    margin-bottom: 32px;
    margin: auto;
    table-layout: fixed;
    width: 100%;
    height: 300px;
  }
  th {
    border: 1px solid #d9d9d9;
    font-size: 18px;
    font-weight: 400;
    line-height: 26.06px;
    padding-top: 19px;
    padding-bottom: 19px;
  }
  max-height: 580px;
  overflow-y: auto;
  /* height: 720px; */
`;

const No = styled.th`
  width: 100px;
`;
const Checkbox = styled.th`
  width: 60px;
`;
const Title = styled.th`
  width: 697px;
`;
const Date = styled.th`
  width: 200px;
`;
const Language = styled.th`
  width: 199px;
`;
const Complete = styled.th`
  width: 198px;
`;
const Amount = styled.th`
  width: 199px;
`;
const Manager = styled.th`
  width: 198px;
`;
const Tr = styled.tr`
  background: #f6f6f6;
`;
const NonData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
`;
