import axios from "axios";
import React, { useState, useRef, useEffect, forwardRef } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import { registerAllModules } from "handsontable/registry";
import Button from "../components/Button";
import useTableData from "../hooks/useTableData";
import Loading from "../components/Loading";
import HandsTable from "../components/HandsTable";
import Handsontable from "handsontable";
import _ from "lodash";
import { HotTable, HotColumn } from "@handsontable/react";
registerAllModules();

const GlobalStyle = createGlobalStyle`
    body {
        display: flex;
        background: #e5e5e5;
        justify-content: center;
        align-items: center;
        height : 100vh;
        width : 100vw;
    }
`;

const fakeData = [
  { time: undefined, text: undefined },
  { time: undefined, text: undefined },
  { time: undefined, text: undefined },
  { time: undefined, text: undefined },
  { time: undefined, text: undefined },
  { time: undefined, text: undefined },
  { time: undefined, text: undefined },
  { time: undefined, text: undefined },
];

export default function SmiParser() {
  let smiDatas = {
    koreaSmi: fakeData,
    englishSmi: fakeData,
    smiList: [],
  };
  let selectRow = {
    type: null,
    number: null,
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const [isSetList, setIsSetList] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [postId, setPostId] = useState(null);
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [smiWorkFile, setSmiWorkFile] = useState("");
  const { korFileName, engFileName, onUpdateTable, updateFileName } =
    useTableData();
  const korTableRef = useRef(null);
  const engTableRef = useRef(null);

  const [koreaFileName, setKoreaFileName] = useState("파일명");
  const [otherFileName, setOtherFileName] = useState("파일명");
  const [korTableData, setKorTableData] = useState("");
  const [isSyncScroll, setIsSyncScroll] = useState(false);

  const [engTableData, setEngTableData] = useState("");
  const localKorListData = localStorage.getItem("sameKorList");
  const localEngListData = localStorage.getItem("sameEngList");

  useEffect(() => {
    const korTableScroll = document.querySelector("#korTable .wtHolder");
    const engTableScroll = document.querySelector("#engTable .wtHolder");

    const korTableScrollEvent = (e) => {
      if (isSyncScroll) {
        const throttle = _.throttle(() => {
          engTableScroll.scrollTo({
            top: e.target.scrollTop,
            left: 0,
            behavior: "auto",
          });
        }, 200);
        throttle();
      }
    };
    const engTableScrollEvent = (e) => {
      if (isSyncScroll) {
        korTableScroll.scrollTo({
          top: e.target.scrollTop,
          left: 0,
          behavior: "auto",
        });
      }
    };
    korTableScroll.addEventListener("scroll", korTableScrollEvent);
    engTableScroll.addEventListener("scroll", engTableScrollEvent);
    return () => {
      korTableScroll.removeEventListener("scroll", korTableScrollEvent);
      engTableScroll.removeEventListener("scroll", engTableScrollEvent);
    };
  }, [isSyncScroll]);

  const onKorSandFile = async (e) => {
    setKoreaFileName(e.target.files[0].name);
    updateFileName(e.target.files[0].name, "kor");

    const formData = new FormData();
    await formData.append("file", e.target.files[0]);
    await formData.append("title", title);
    setLoading(true);
    await axios({
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data; charset=UTF-8",
      },
      // url: `http://subtitle.insightvessel.io/backend/upload`,
      url: "https://ivsmi.tideflo.work/backend/upload",

      data: formData,
    })
      .then(async (res) => {
        if (res.data === "") {
          return;
        }
        const data = res.data.data;
        const list = [];
        data.forEach((item) => {
          const obj = {
            time: Number(item.time.split(" ")[1]),
            text: item.text,
          };
          list.push(obj);
        });
        setKorTableData(list);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const onEngSandFile = async (e) => {
    setOtherFileName(e.target.files[0].name);
    updateFileName(e.target.files[0].name, "eng");

    const formData = new FormData();
    await formData.append("file", e.target.files[0]);
    await formData.append("title", title);
    await axios({
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data; charset=UTF-8",
      },
      // url: `http://subtitle.insightvessel.io/backend/upload`,
      url: "https://ivsmi.tideflo.work/backend/upload",

      data: formData,
    })
      .then(async (res) => {
        if (res.data === "") {
          return;
        }
        const data = res.data.data;
        const list = [];
        data.forEach((item) => {
          const obj = {
            time: Number(item.time.split(" ")[1]),
            text: item.text,
          };
          list.push(obj);
        });
        setLoading(false);
        setEngTableData(list);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };
  useEffect(() => {
    if (!sessionStorage.getItem("Login")) {
      if (window.confirm("로그인이 필요합니다. 로그인 하시겠습니까?")) {
        navigate("/login");
      } else {
        navigate("/login");
      }
    }
    if (id !== undefined) {
      setIsSetList(true);
    }
  }, []);

  const getSmiWorkspace = async () => {
    const response = await axios({
      method: "post",
      // url: "http://subtitle.insightvessel.io/backend/load",
      url: "https://ivsmi.tideflo.work/backend/load",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        id: id,
      }),
    }).then((res) => {
      console.log(res.data);
      return res.data.data;
    });

    try {
      await setLoading(true);
      await setSmiWorkFile(response);
      await updateFileName(response.file_kor, "kor");
      await updateFileName(response.file_eng, "eng");
      await setIsChecked(response.complete);
      await setKorTableData(response.kor_smi);
      await setEngTableData(response.eng_smi);
      await setKoreaFileName(response.file_kor);
      await setOtherFileName(response.file_eng);
      await setTitle(response.title);
      await setLoading(false);
    } catch (err) {
      console.log(err);
      await setLoading(false);
    }
  };

  useEffect(() => {
    localStorage.setItem("sameKorList", "[]");
    localStorage.setItem("sameEngList", "[]");
    if (id === undefined) return;
    return getSmiWorkspace();
  }, []);
  useEffect(() => {
    if (korTableData !== "") {
      smiDatas.koreaSmi = korTableData;
    }
    if (engTableData !== "") {
      smiDatas.englishSmi = engTableData;
    }
    if (id !== undefined) {
      smiDatas.koreaSmi = korTableData;
      smiDatas.englishSmi = engTableData;
    }
    onReturnSameData();
  }, [korTableData, engTableData, loading]);

  useEffect(() => {
    smiDatas.koreaSmi = korTableData;
    smiDatas.englishSmi = engTableData;
  }, [title, isChecked]);

  const onReturnSameData = async () => {
    if (smiDatas.koreaSmi === fakeData || smiDatas.englishSmi === fakeData)
      return;
    const korData = korTableRef.current.hotInstance.getData(
      korTableData.length,
      2
    );
    const engData = engTableRef.current.hotInstance.getData(
      engTableData.length,
      1
    );
    const korDataTimeList = [];
    const engDataList = [];
    korData.forEach((idx) => {
      const obj = Number(idx[0]);
      korDataTimeList.push(obj);
    });
    engData.forEach((idx) => {
      const obj = Number(idx[0]);
      engDataList.push(obj);
    });
    const sameEngList = [];
    const sameKorList = [];
    for (let i = 0; i < korDataTimeList.length; i++) {
      if (engDataList.includes(korDataTimeList[i]) === true) {
        sameKorList.push(Number(i));
      }
    }
    for (let i = 0; i < engDataList.length; i++) {
      if (korDataTimeList.includes(engDataList[i]) === true) {
        sameEngList.push(Number(i));
      }
    }
    await localStorage.setItem("sameEngList", JSON.stringify(sameEngList));
    await localStorage.setItem("sameKorList", JSON.stringify(sameKorList));
    const korListData = [];
    const engListData = [];
    const localKorListData = await localStorage.getItem("sameKorList");
    const localEngListData = await localStorage.getItem("sameEngList");
    await korData.forEach((idx, key) => {
      const list = {
        time: Number(idx[0]),
        toward:
          JSON.parse(localKorListData).includes(key) === true
            ? key + 1
            : undefined,
        text: idx[2],
      };
      korListData.push(list);
    });
    smiDatas.koreaSmi = korListData;
    const engTimeList = [];
    await engData.forEach((idx, key) => {
      const toward = JSON.parse(localEngListData).includes(key)
        ? key + 1
        : undefined;
      const list = {
        time: Number(idx[0]),
        toward: toward,
        text: idx[1],
      };
      engListData.push(list);
      engTimeList.push(Number(idx[0]));
    });
    smiDatas.englishSmi = engListData;

    engTableRef.current.hotInstance.updateSettings({
      cells: function (row, col) {
        let cellProperties = {};

        cellProperties.renderer = function (
          instance,
          td,
          cellrow,
          cellcol,
          prop,
          value,
          cellProperties
        ) {
          Handsontable.renderers.TextRenderer.apply(this, arguments);
          if (
            JSON.parse(localStorage.getItem("sameEngList")).findIndex(
              (e) => e === row
            ) !== -1
          ) {
            td.style.background = "rgba(41, 155, 62, 0.1)";
            if (
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 0) ===
                "" ||
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 0) ===
                undefined ||
              engTableRef.current.hotInstance.getDataAtCell(cellrow, 0) ===
                "" ||
              engTableRef.current.hotInstance.getDataAtCell(cellrow, 0) ===
                undefined ||
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 0) ===
                null ||
              engTableRef.current.hotInstance.getDataAtCell(cellrow, 0) === null
              // korTableRef.current.hotInstance.getDataAtCell(cellrow, 0) === 0 ||
              // engTableRef.current.hotInstance.getDataAtCell(cellrow, 0) === 0
            ) {
              td.style.background = "#ffffff";
            }
            // if (isSetList) {
            if (
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 2) ===
                undefined &&
              engTableRef.current.hotInstance.getDataAtCell(cellrow, 1) !==
                undefined
            ) {
              td.style.background = "#ffffff";
            } else if (
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 2) !==
                undefined &&
              engTableRef.current.hotInstance.getDataAtCell(cellrow, 1) ===
                undefined
            ) {
              td.style.background = "#ffffff";
            } else if (
              engTableRef.current.hotInstance.getDataAtCell(cellrow, 1) !==
                "" &&
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 2) === ""
            ) {
              td.style.background = "#ffffff";
            } else if (
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 2) !==
                "" &&
              engTableRef.current.hotInstance.getDataAtCell(cellrow, 1) === ""
            ) {
              td.style.background = "#ffffff";
            } else if (
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 2) !==
                null &&
              engTableRef.current.hotInstance.getDataAtCell(cellrow, 1) === ""
            ) {
              td.style.background = "#ffffff";
            } else if (
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 2) !==
                "" &&
              engTableRef.current.hotInstance.getDataAtCell(cellrow, 1) === null
            ) {
              td.style.background = "#ffffff";
            } else if (
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 2) ===
                undefined &&
              engTableRef.current.hotInstance.getDataAtCell(cellrow, 1) !== ""
            ) {
              td.style.background = "#ffffff";
            } else if (
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 2) ===
                "" &&
              engTableRef.current.hotInstance.getDataAtCell(cellrow, 1) ===
                undefined
            ) {
              td.style.background = "#ffffff";
            } else if (
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 2) ===
                undefined &&
              engTableRef.current.hotInstance.getDataAtCell(cellrow, 1) === null
            ) {
              td.style.background = "#ffffff";
            }
            // }
          }
        };
        return cellProperties;
      },
    });

    korTableRef.current.hotInstance.updateSettings({
      cells: function (row, col) {
        let cellProperties = {};

        cellProperties.renderer = function (
          instance,
          td,
          cellrow,
          cellcol,
          prop,
          value,
          cellProperties
        ) {
          Handsontable.renderers.TextRenderer.apply(this, arguments);
          if (
            JSON.parse(localStorage.getItem("sameKorList")).findIndex(
              (e) => e === row
            ) !== -1
          ) {
            td.style.background = "rgba(41, 155, 62, 0.1)";
            if (
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 0) ===
                "" ||
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 0) ===
                undefined ||
              engTableRef.current.hotInstance.getDataAtCell(cellrow, 0) ===
                "" ||
              engTableRef.current.hotInstance.getDataAtCell(cellrow, 0) ===
                undefined ||
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 0) ===
                null ||
              engTableRef.current.hotInstance.getDataAtCell(cellrow, 0) === null
              // korTableRef.current.hotInstance.getDataAtCell(cellrow, 0) === 0 ||
              // engTableRef.current.hotInstance.getDataAtCell(cellrow, 0) === 0
            ) {
              td.style.background = "#ffffff";
            }
            // if (isSetList) {
            if (
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 2) ===
                undefined &&
              engTableRef.current.hotInstance.getDataAtCell(cellrow, 1) !==
                undefined
            ) {
              td.style.background = "#ffffff";
            } else if (
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 2) !==
                undefined &&
              engTableRef.current.hotInstance.getDataAtCell(cellrow, 1) ===
                undefined
            ) {
              td.style.background = "#ffffff";
            } else if (
              engTableRef.current.hotInstance.getDataAtCell(cellrow, 1) !==
                "" &&
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 2) === ""
            ) {
              td.style.background = "#ffffff";
            } else if (
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 2) !==
                "" &&
              engTableRef.current.hotInstance.getDataAtCell(cellrow, 1) === ""
            ) {
              td.style.background = "#ffffff";
            } else if (
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 2) !==
                null &&
              engTableRef.current.hotInstance.getDataAtCell(cellrow, 1) === ""
            ) {
              td.style.background = "#ffffff";
            } else if (
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 2) !==
                "" &&
              engTableRef.current.hotInstance.getDataAtCell(cellrow, 1) === null
            ) {
              td.style.background = "#ffffff";
            } else if (
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 2) ===
                undefined &&
              engTableRef.current.hotInstance.getDataAtCell(cellrow, 1) !== ""
            ) {
              td.style.background = "#ffffff";
            } else if (
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 2) ===
                "" &&
              engTableRef.current.hotInstance.getDataAtCell(cellrow, 1) ===
                undefined
            ) {
              td.style.background = "#ffffff";
            } else if (
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 2) ===
                undefined &&
              engTableRef.current.hotInstance.getDataAtCell(cellrow, 1) === null
            ) {
              td.style.background = "#ffffff";
            } else if (
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 2) ===
                undefined &&
              engTableRef.current.hotInstance.getDataAtCell(cellrow, 1) ===
                undefined &&
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 0) ===
                engTableRef.current.hotInstance.getDataAtCell(cellrow, 0)
            ) {
              td.style.background = "rgba(41, 155, 62, 0.1)";
            } else if (
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 2) ===
                "" &&
              engTableRef.current.hotInstance.getDataAtCell(cellrow, 1) ===
                "" &&
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 0) ===
                engTableRef.current.hotInstance.getDataAtCell(cellrow, 0)
            ) {
              td.style.background = "rgba(41, 155, 62, 0.1)";
            } else if (
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 2) ===
                undefined &&
              engTableRef.current.hotInstance.getDataAtCell(cellrow, 1) ===
                undefined &&
              korTableRef.current.hotInstance.getDataAtCell(cellrow, 0) ===
                engTableRef.current.hotInstance.getDataAtCell(cellrow, 0)
            ) {
              td.style.background = "rgba(41, 155, 62, 0.1)";
            }
            // }
          }
          for (let i = 0; i < korListData.length; i++) {
            const sameTimeIndex = engTimeList.indexOf(korListData[i].time);
            if (sameTimeIndex !== -1 && i === cellrow && col === 1) {
              td.innerHTML = sameTimeIndex + 1;
              if (
                korTableRef.current.hotInstance.getDataAtCell(cellrow, 2) ===
                  undefined ||
                korTableRef.current.hotInstance.getDataAtCell(cellrow, 2) === ""
              ) {
                td.innerHTML = "";
              }
              if (
                engTableRef.current.hotInstance.getDataAtCell(cellrow, 1) ===
                  "" ||
                engTableRef.current.hotInstance.getDataAtCell(cellrow, 1) ===
                  undefined
              ) {
                td.innerHTML = "";
              }
              if (
                korTableRef.current.hotInstance.getDataAtCell(cellrow, 0) ===
                  undefined ||
                korTableRef.current.hotInstance.getDataAtCell(cellrow, 0) ===
                  "" ||
                korTableRef.current.hotInstance.getDataAtCell(cellrow, 0) ===
                  null ||
                korTableRef.current.hotInstance.getDataAtCell(cellrow, 0) === 0
              ) {
                td.innerHTML = "";
              }
            }
          }
          if (col === 1) {
            cellProperties.readOnly = true;
          }
        };
        return cellProperties;
      },
    });
  };

  const onClick = async () => {
    if (korFileName === null || engFileName === null) {
      return alert("자막 파일을 모두 올려주세요.");
    }
    if (title === "") {
      return alert("제목을 입력해주세요.");
    }
    const korLength = await korTableRef.current.props.settings.data.length;
    const engLength = await engTableRef.current.props.settings.data.length;
    const korData = await korTableRef.current.hotInstance.getData(korLength, 2);
    const engData = await engTableRef.current.hotInstance.getData(engLength, 1);
    const newKorData = [];
    korData.forEach((idx) => {
      const list = [idx[0], idx[2]];
      newKorData.push(list);
    });

    const datas = JSON.stringify({
      id: id === undefined ? "null" : id,
      title: title,
      complete: isChecked,
      file_kor: korFileName,
      file_eng: engFileName,
      kor_smi: newKorData,
      eng_smi: engData,
    });
    const response = await axios({
      method: "post",
      // url: "http://subtitle.insightvessel.io/backend/storage",
      url: "https://ivsmi.tideflo.work/backend/storage",

      headers: {
        "Content-Type": "application/json",
      },
      data: datas,
    }).then(async (res) => {
      alert("저장이 완료되었습니다.");
      if (id === undefined) {
        navigate(`/smi/${res.data.data.id}`, { replace: true });
      }
      return res.data.data;
    });
    try {
      await setLoading(true);
      await setSmiWorkFile(response);
      await setLoading(false);
    } catch (e) {
      console.log(e);
      await setLoading(false);
    }
  };

  const onDeleteClick = async (id) => {
    const data = await JSON.stringify({
      id: id,
    });
    if (
      window.confirm(
        "정말로 삭제하시겠습니까?\n삭제된 작업은 복구가 불가합니다."
      )
    ) {
      await axios({
        method: "post",
        // url: "http://subtitle.insightvessel.io/backend/deletion",
        url: "https://ivsmi.tideflo.work/backend/deletion",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      })
        .then((res) => {
          console.log("result", res.data);
          window.location.replace("/main");
        })
        .catch((e) => console.log("error", e));
    }
  };

  const onExcelClick = () => {
    const fileId = id === undefined ? postId : id;
    if (
      window.confirm(
        "저장하지 않은 데이터는 반영되지 않습니다.다운로드 하시겠습니까?"
      )
    ) {
      // window.location.href = `http://subtitle.insightvessel.io/backend/excel?id=${fileId}`;
      window.location.href = `https://ivsmi.tideflo.work/backend/excel?id=${fileId}`;
    }
  };

  const onGoBackClick = () => {
    if (
      window.confirm("저장하지 않은 데이터는 소멸됩니다.\n이동하시겠습니까?")
    ) {
      navigate("/main");
    }
  };

  const setSmiList = () => {
    if (koreaFileName === "파일명" || otherFileName === "파일명") {
      return alert("자막 파일을 모두 올려 주세요.");
    }
    const korData = korTableRef.current.hotInstance.getData(
      korTableData.length,
      2
    );
    const engData = engTableRef.current.hotInstance.getData(
      engTableData.length,
      1
    );
    for (let i = 1; i < korData.length; i++) {
      if (korData[i][0] === "") {
        alert(`(한글자막) ${i + 1}번째 자막이 비어있습니다.`);
        return korTableRef.current.hotInstance.scrollViewportTo(i);
      }
      if (Number(korData[i - 1][0]) > Number(korData[i][0])) {
        alert(
          `(한글자막) ${i}번째 자막이 ${i + 1}번째 자막의 시간보다 느립니다.`
        );
        return korTableRef.current.hotInstance.scrollViewportTo(i - 1);
      }
    }
    for (let i = 1; i < engData.length; i++) {
      if (engData[i][0] === "") {
        alert(`(영어자막) ${i + 1}번째 자막이 비어있습니다.`);
        return engTableRef.current.hotInstance.scrollViewportTo(i);
      }
      if (engData[i - 1][0] > engData[i][0]) {
        alert(`(영어자막) ${i + 1}번째 자막의 시간값을 수정해주세요.`);
        return engTableRef.current.hotInstance.scrollViewportTo(i - 1);
      }
    }
    const korDataList = korData.map((item) => {
      return {
        time: Number(item[0]),
        toward: item[1],
        text: item[2],
      };
    });
    const engDataList = engData.map((item) => {
      return {
        time: Number(item[0]),
        text: item[1],
      };
    });
    // console.log(korDataList);
    const korTimeList = korData.map((idx) => {
      if (idx[0] === null) {
        return null;
      }
      return Number(idx[0]);
    });

    const engTimeList = engData.map((idx) => {
      if (idx[0] === null) {
        return null;
      }
      return Number(idx[0]);
    });
    if (korTimeList.includes(null) || engTimeList.includes(null)) {
      alert("시간값을 채워주세요.");
      return;
    }
    setIsSetList(true);
    korTimeList.sort((a, b) => a - b);
    engTimeList.sort((a, b) => a - b);

    const mergeTimeList = korTimeList.concat(...engTimeList);
    const timeList = mergeTimeList.filter((item, pos) => {
      return mergeTimeList.indexOf(item) === pos;
    });
    const sortTimeList = timeList.sort((a, b) => a - b);

    const newKorData = [];
    const newEngData = [];

    if (korDataList.length > engDataList.length) {
      let korI = 0;
      let timeI = 0;
      while (timeI < sortTimeList.length) {
        if (korI === korDataList.length) {
          break;
        }
        if (sortTimeList[timeI] === Number(korDataList[korI].time)) {
          const obj = {
            time: sortTimeList[timeI],
            toward: undefined,
            text: korDataList[korI].text,
          };
          newKorData.push(obj);
          korI += 1;
          timeI += 1;
        } else if (
          korI > 0 &&
          Number(korDataList[korI].time) === Number(korDataList[korI - 1].time)
        ) {
          const obj = {
            time: korDataList[korI].time,
            toward: undefined,
            text: korDataList[korI].text,
          };
          newKorData.push(obj);
          korI += 1;
        } else if (sortTimeList[timeI] !== Number(korDataList[korI].time)) {
          const obj = {
            time: sortTimeList[timeI],
            toward: undefined,
            text: undefined,
          };
          timeI += 1;
          newKorData.push(obj);
        } else {
          const obj = {
            time: sortTimeList[timeI],
            toward: undefined,
            text: undefined,
          };
          newKorData.push(obj);
          timeI += 1;
        }
      }
      const newTimeList = newKorData.map((item) => {
        return item.time;
      });

      smiDatas.smiList = newTimeList;

      let engI = 0;
      let sameTimeEngI = 0;
      while (sameTimeEngI < newTimeList.length) {
        if (engI === engDataList.length) {
          break;
        }
        if (newTimeList[sameTimeEngI] === Number(engDataList[engI].time)) {
          const obj = {
            time: engDataList[engI].time,
            toward: undefined,
            text: engDataList[engI].text,
          };
          newEngData.push(obj);
          engI += 1;
          sameTimeEngI += 1;
        } else if (
          engI > 0 &&
          Number(engDataList[engI].time) === Number(engDataList[engI - 1].time)
        ) {
          const obj = {
            time: engDataList[engI].time,
            toward: undefined,
            text: engDataList[engI].text,
          };
          newEngData.push(obj);
          engI += 1;
        } else if (newTimeList[sameTimeEngI] !== engDataList[engI].time) {
          const obj = {
            time: newTimeList[sameTimeEngI],
            toward: undefined,
            text: undefined,
          };
          sameTimeEngI += 1;
          newEngData.push(obj);
        }
      }
      setKorTableData(newKorData);
      setEngTableData(newEngData);
    } else if (korDataList.length < engDataList.length) {
      let engI = 0;
      let sameTimeEngI = 0;
      while (sameTimeEngI < sortTimeList.length) {
        if (engI === engDataList.length) {
          break;
        }
        if (sortTimeList[sameTimeEngI] === Number(engDataList[engI].time)) {
          const obj = {
            time: engDataList[engI].time,
            toward: undefined,
            text: engDataList[engI].text,
          };
          newEngData.push(obj);
          engI += 1;
          sameTimeEngI += 1;
        } else if (
          engI > 0 &&
          Number(engDataList[engI].time) === Number(engDataList[engI - 1].time)
        ) {
          const obj = {
            time: engDataList[engI].time,
            toward: undefined,
            text: engDataList[engI].text,
          };
          newEngData.push(obj);
          engI += 1;
        } else if (sortTimeList[sameTimeEngI] !== engDataList[engI].time) {
          const obj = {
            time: sortTimeList[sameTimeEngI],
            toward: undefined,
            text: undefined,
          };
          sameTimeEngI += 1;
          newEngData.push(obj);
        }
      }

      const newTimeList = newEngData.map((item) => {
        return item.time;
      });
      smiDatas.smiList = newTimeList;

      let korI = 0;
      let timeI = 0;
      while (timeI < newTimeList.length) {
        if (korI === korDataList.length) {
          break;
        }
        if (newTimeList[timeI] === Number(korDataList[korI].time)) {
          const obj = {
            time: newTimeList[timeI],
            toward: undefined,
            text: korDataList[korI].text,
          };
          newKorData.push(obj);
          korI += 1;
          timeI += 1;
        } else if (
          korI > 0 &&
          Number(korDataList[korI].time) === Number(korDataList[korI - 1].time)
        ) {
          const obj = {
            time: korDataList[korI].time,
            toward: undefined,
            text: korDataList[korI].text,
          };
          newKorData.push(obj);
          korI += 1;
        } else if (newTimeList[timeI] !== Number(korDataList[korI].time)) {
          const obj = {
            time: newTimeList[timeI],
            toward: undefined,
            text: undefined,
          };
          timeI += 1;
          newKorData.push(obj);
        } else {
          const obj = {
            time: sortTimeList[timeI],
            toward: undefined,
            text: undefined,
          };
          newKorData.push(obj);
          timeI += 1;
        }
      }

      setKorTableData(newKorData);
      setEngTableData(newEngData);
    } else {
      let korI = 0;
      let timeI = 0;
      while (timeI < sortTimeList.length) {
        if (korI === korDataList.length) {
          break;
        }
        if (sortTimeList[timeI] === Number(korDataList[korI].time)) {
          const obj = {
            time: sortTimeList[timeI],
            toward: undefined,
            text: korDataList[korI].text,
          };
          newKorData.push(obj);
          korI += 1;
          timeI += 1;
        } else if (
          korI > 0 &&
          Number(korDataList[korI].time) === Number(korDataList[korI - 1].time)
        ) {
          const obj = {
            time: korDataList[korI].time,
            toward: undefined,
            text: korDataList[korI].text,
          };
          newKorData.push(obj);
          korI += 1;
        } else if (sortTimeList[timeI] !== Number(korDataList[korI].time)) {
          const obj = {
            time: sortTimeList[timeI],
            toward: undefined,
            text: undefined,
          };
          timeI += 1;
          newKorData.push(obj);
        } else {
          const obj = {
            time: sortTimeList[timeI],
            toward: undefined,
            text: undefined,
          };
          newKorData.push(obj);
          timeI += 1;
        }
      }

      let engI = 0;
      let sameTimeEngI = 0;
      while (sameTimeEngI < sortTimeList.length) {
        if (engI === engDataList.length) {
          break;
        }
        if (sortTimeList[sameTimeEngI] === Number(engDataList[engI].time)) {
          const obj = {
            time: engDataList[engI].time,
            toward: undefined,
            text: engDataList[engI].text,
          };
          newEngData.push(obj);
          engI += 1;
          sameTimeEngI += 1;
        } else if (
          engI > 0 &&
          Number(engDataList[engI].time) === Number(engDataList[engI - 1].time)
        ) {
          const obj = {
            time: engDataList[engI].time,
            toward: undefined,
            text: engDataList[engI].text,
          };
          newEngData.push(obj);
          engI += 1;
        } else if (sortTimeList[sameTimeEngI] !== engDataList[engI].time) {
          const obj = {
            time: sortTimeList[sameTimeEngI],
            toward: undefined,
            text: undefined,
          };
          sameTimeEngI += 1;
          newEngData.push(obj);
        }
      }
      smiDatas.smiList = sortTimeList;

      setKorTableData(newKorData);
      setEngTableData(newEngData);
    }
    smiDatas.koreaSmi = newKorData;
    smiDatas.englishSmi = newEngData;
    onReturnSameData();
  };

  const korSettings = {
    data: korTableData === "" ? fakeData : korTableData,
    colHeaders: ["TIME", "순서", "자막 내용"],
    columns: [
      { data: "time", type: "numeric" },
      { data: "toward", readOnly: true },
      { data: "text" },
    ],
    colWidths: [90, 90, 448],
    columnHeaderHeight: 64,
    rowHeaders: true,
    rowHeights: 64,
    manualRowMove: true,
    readOnly: false,
    rowHeaderWidth: 30,
    rowWidths: 10,
    bindRowsWithHeaders: false,
    licenseKey: "non-commercial-and-evaluation",
    manualRowResize: true,
    className: "htCenter htMiddle",
    contextMenu: !isSetList
      ? {
          items: {
            copy: {
              name: "복사하기",
            },
            row_below: {
              name: "아래에 줄 추가",
              callback: function (e, r, t) {
                const row = r[0].start.row;
                const hotKor = korTableRef.current.hotInstance;
                hotKor.alter("insert_row", row + 1);
                onReturnSameData();
                hotKor.setDataAtCell(row + 1, 0, undefined);
                hotKor.setDataAtCell(row + 1, 1, undefined);
                hotKor.setDataAtCell(row + 1, 2, undefined);
              },
            },
            remove_row: {
              name: "줄 삭제",
            },
            undo: {
              name: "되돌리기",
            },
          },
        }
      : {
          items: {
            copy: {
              name: "복사하기",
            },
            row_below: {
              name: "아래에 줄 추가",
              callback: function (e, r, t) {
                const row = r[0].start.row;
                const hotKor = korTableRef.current.hotInstance;
                const hotEng = engTableRef.current.hotInstance;
                hotKor.alter("insert_row", row + 1);
                const korData = korTableRef.current.hotInstance.getData(
                  korTableData.length,
                  2
                );
                const engData = engTableRef.current.hotInstance.getData(
                  engTableData.length,
                  1
                );
                const korListData = [];
                const engListData = [];
                const localKorListData = localStorage.getItem("sameKorList");
                const localEngListData = localStorage.getItem("sameEngList");
                korData.forEach((idx, key) => {
                  const list = {
                    time: Number(idx[0]),
                    toward:
                      JSON.parse(localKorListData).includes(key) === true
                        ? key + 1
                        : undefined,
                    text: idx[2],
                  };
                  korListData.push(list);
                });
                smiDatas.koreaSmi = korListData;
                const engTimeList = [];
                engData.forEach((idx, key) => {
                  const toward = JSON.parse(localEngListData).includes(key)
                    ? key + 1
                    : undefined;
                  const list = {
                    time: Number(idx[0]),
                    toward: toward,
                    text: idx[1],
                  };
                  engListData.push(list);
                  engTimeList.push(Number(idx[0]));
                });
                smiDatas.englishSmi = engListData;
                hotKor.updateSettings({
                  cells: function (row, col) {
                    let cellProperties = {};

                    cellProperties.renderer = function (
                      instance,
                      td,
                      cellrow,
                      cellcol,
                      prop,
                      value,
                      cellProperties
                    ) {
                      Handsontable.renderers.TextRenderer.apply(
                        this,
                        arguments
                      );
                      if (
                        hotKor.getDataAtCell(cellrow, 0) ===
                        hotEng.getDataAtCell(cellrow, 0)
                      ) {
                        td.style.background = "rgba(41, 155, 62, 0.1)";

                        if (
                          hotKor.getDataAtCell(cellrow, 2) === undefined ||
                          hotEng.getDataAtCell(cellrow, 1) === undefined ||
                          hotKor.getDataAtCell(cellrow, 2) === "" ||
                          hotEng.getDataAtCell(cellrow, 1) === "" ||
                          hotKor.getDataAtCell(cellrow, 2) === null ||
                          hotEng.getDataAtCell(cellrow, 1) === null
                        ) {
                          td.style.background = "#ffffff";
                        }

                        for (let i = 0; i < korListData.length; i++) {
                          const sameTimeIndex = engTimeList.indexOf(
                            korListData[i].time
                          );
                          if (
                            sameTimeIndex !== -1 &&
                            i === cellrow &&
                            col === 1
                          ) {
                            td.innerHTML = sameTimeIndex + 1;
                            if (
                              korTableRef.current.hotInstance.getDataAtCell(
                                cellrow,
                                2
                              ) === undefined ||
                              korTableRef.current.hotInstance.getDataAtCell(
                                cellrow,
                                2
                              ) === ""
                            ) {
                              td.innerHTML = "";
                            }
                            if (
                              engTableRef.current.hotInstance.getDataAtCell(
                                cellrow,
                                1
                              ) === "" ||
                              engTableRef.current.hotInstance.getDataAtCell(
                                cellrow,
                                1
                              ) === undefined
                            ) {
                              td.innerHTML = "";
                            }
                          }
                        }
                      }
                    };
                    return cellProperties;
                  },
                });

                hotEng.updateSettings({
                  cells: function (row, col) {
                    let cellProperties = {};

                    cellProperties.renderer = function (
                      instance,
                      td,
                      cellrow,
                      cellcol,
                      prop,
                      value,
                      cellProperties
                    ) {
                      Handsontable.renderers.TextRenderer.apply(
                        this,
                        arguments
                      );
                      if (
                        hotKor.getDataAtCell(cellrow, 0) ===
                        hotEng.getDataAtCell(cellrow, 0)
                      ) {
                        td.style.background = "rgba(41, 155, 62, 0.1)";

                        if (
                          hotKor.getDataAtCell(cellrow, 2) === undefined ||
                          hotEng.getDataAtCell(cellrow, 1) === undefined ||
                          hotKor.getDataAtCell(cellrow, 2) === "" ||
                          hotEng.getDataAtCell(cellrow, 1) === "" ||
                          hotKor.getDataAtCell(cellrow, 2) === null ||
                          hotEng.getDataAtCell(cellrow, 1) === null
                        ) {
                          td.style.background = "#ffffff";
                        }
                      }
                    };
                    return cellProperties;
                  },
                });
              },
            },
            remove_row: {
              name: "줄 삭제",
              callback: function (e, r) {
                const startRow = r[0].start.row;
                const endRow = r[0].end.row;
                const hotKor = korTableRef.current.hotInstance;
                const hotEng = engTableRef.current.hotInstance;
                startRow === endRow
                  ? hotKor.alter("remove_row", startRow)
                  : hotKor.alter("remove_row", startRow, endRow - startRow + 1);
                const korData = korTableRef.current.hotInstance.getData(
                  korTableData.length,
                  2
                );
                const engData = engTableRef.current.hotInstance.getData(
                  engTableData.length,
                  1
                );
                const korListData = [];
                const engListData = [];
                const localKorListData = localStorage.getItem("sameKorList");
                const localEngListData = localStorage.getItem("sameEngList");
                korData.forEach((idx, key) => {
                  const list = {
                    time: Number(idx[0]),
                    toward:
                      JSON.parse(localKorListData).includes(key) === true
                        ? key + 1
                        : undefined,
                    text: idx[2],
                  };
                  korListData.push(list);
                });
                smiDatas.koreaSmi = korListData;

                // hotKor.updateData(korListData);
                const engTimeList = [];
                engData.forEach((idx, key) => {
                  const toward = JSON.parse(localEngListData).includes(key)
                    ? key + 1
                    : undefined;
                  const list = {
                    time: Number(idx[0]),
                    toward: toward,
                    text: idx[1],
                  };
                  engListData.push(list);
                  engTimeList.push(Number(idx[0]));
                });
                smiDatas.englishSmi = engListData;
                // hotEng.updateData(engListData);
                hotKor.updateSettings({
                  cells: function (row, col) {
                    let cellProperties = {};
                    cellProperties.renderer = function (
                      instance,
                      td,
                      cellrow,
                      cellcol,
                      prop,
                      value,
                      cellProperties
                    ) {
                      Handsontable.renderers.TextRenderer.apply(
                        this,
                        arguments
                      );
                      if (
                        hotKor.getDataAtCell(cellrow, 0) ===
                        hotEng.getDataAtCell(cellrow, 0)
                      ) {
                        td.style.background = "rgba(41, 155, 62, 0.1)";
                        if (
                          hotKor.getDataAtCell(cellrow, 2) === undefined ||
                          hotEng.getDataAtCell(cellrow, 1) === undefined ||
                          hotKor.getDataAtCell(cellrow, 2) === "" ||
                          hotEng.getDataAtCell(cellrow, 1) === "" ||
                          hotKor.getDataAtCell(cellrow, 2) === null ||
                          hotEng.getDataAtCell(cellrow, 1) === null
                        ) {
                          td.style.background = "#ffffff";
                        }
                        for (let i = 0; i < korListData.length; i++) {
                          const sameTimeIndex = engTimeList.indexOf(
                            korListData[i].time
                          );
                          if (
                            sameTimeIndex !== -1 &&
                            i === cellrow &&
                            col === 1
                          ) {
                            td.innerHTML = sameTimeIndex + 1;
                            if (
                              korTableRef.current.hotInstance.getDataAtCell(
                                cellrow,
                                2
                              ) === undefined ||
                              korTableRef.current.hotInstance.getDataAtCell(
                                cellrow,
                                2
                              ) === ""
                            ) {
                              td.innerHTML = "";
                            }
                            if (
                              engTableRef.current.hotInstance.getDataAtCell(
                                cellrow,
                                1
                              ) === "" ||
                              engTableRef.current.hotInstance.getDataAtCell(
                                cellrow,
                                1
                              ) === undefined
                            ) {
                              td.innerHTML = "";
                            }
                          }
                        }
                      }
                    };
                    return cellProperties;
                  },
                });
                hotEng.updateSettings({
                  cells: function (row, col) {
                    let cellProperties = {};
                    cellProperties.renderer = function (
                      instance,
                      td,
                      cellrow,
                      cellcol,
                      prop,
                      value,
                      cellProperties
                    ) {
                      Handsontable.renderers.TextRenderer.apply(
                        this,
                        arguments
                      );
                      if (
                        hotKor.getDataAtCell(cellrow, 0) ===
                        hotEng.getDataAtCell(cellrow, 0)
                      ) {
                        td.style.background = "rgba(41, 155, 62, 0.1)";
                        if (
                          hotKor.getDataAtCell(cellrow, 2) === undefined ||
                          hotEng.getDataAtCell(cellrow, 1) === undefined ||
                          hotKor.getDataAtCell(cellrow, 2) === "" ||
                          hotEng.getDataAtCell(cellrow, 1) === "" ||
                          hotKor.getDataAtCell(cellrow, 2) === null ||
                          hotEng.getDataAtCell(cellrow, 1) === null
                        ) {
                          td.style.background = "#ffffff";
                        }
                      }
                    };
                    return cellProperties;
                  },
                });
              },
            },
            undo: {
              name: "되돌리기",
            },
          },
        },
    undo: true,
    // autoRowSize: true,
    // afterScrollVertically: function () {
    //   const autoRowSize =
    //     korTableRef.current.hotInstance.getPlugin("AutoRowSize");
    //   if (isSyncScroll) {
    //     const korTableCurrentRow = autoRowSize.getFirstVisibleRow();
    //     engTableRef.current.hotInstance.scrollViewportTo(
    //       korTableCurrentRow - 1
    //     );
    //   }
    // },
    afterRowMove: function (e) {
      if (korTableRef.current === null) return;

      if (smiDatas.koreaSmi === fakeData || smiDatas.englishSmi === fakeData)
        return;

      const length = korTableRef.current.props.settings.data.length;
      const smiData = korTableRef.current.hotInstance.getData(length, 2);
      const dataList = [];
      smiData.forEach((idx, key) => {
        const list = {
          time: idx[0],
          text: idx[2],
        };
        dataList.push(list);
      });
      smiDatas.koreaSmi = dataList;
      if (!isSetList) {
        onReturnSameData();
      }
    },
    afterChange: function (e, r, t, y) {
      if (korTableRef.current === null) return;
      if (smiDatas.koreaSmi === fakeData || smiDatas.englishSmi === fakeData)
        return;
      const hotKor = korTableRef.current.hotInstance;
      const hotEng = engTableRef.current.hotInstance;
      console.log(e[0][3]);
      if (e !== null) {
        if (e[0][3] === "") {
          return;
        }
        if (isNaN(Number(e[0][3])) && e[0][1] === "time") {
          alert("시간값은 숫자만 입력이 가능합니다.");
          return hotKor.setDataAtCell(e[0][0], 0, e[0][2]);
        }
        if (
          Number(e[0][3]) < hotKor.getDataAtCell(e[0][0] - 1, 0) &&
          e[0][1] === "time"
        ) {
          alert("시간 입력값은 이전 시간보다 빠를 수 없습니다.");
          return hotKor.setDataAtCell(e[0][0], 0, "");
        }
        if (Number(e[0][3]) > hotKor.getDataAtCell(e[0][0] + 1, 0)) {
          alert("시간 입력값은 다음 시간값보다 느릴 수 없습니다.");
          return hotKor.setDataAtCell(e[0][0], 0, "");
        }
      }

      const length = korTableRef.current.props.settings.data.length;
      const smiData = korTableRef.current.hotInstance.getData(length, 2);
      const dataList = [];
      smiData.forEach((idx, key) => {
        const list = {
          time: idx[0],
          text: idx[2],
        };
        dataList.push(list);
      });
      smiDatas.koreaSmi = dataList;
      if (!isSetList) {
        onReturnSameData();
      } else {
        const korData = korTableRef.current.hotInstance.getData(
          korTableData.length,
          2
        );
        const engData = engTableRef.current.hotInstance.getData(
          engTableData.length,
          1
        );
        const korListData = [];
        const engListData = [];
        const localKorListData = localStorage.getItem("sameKorList");
        const localEngListData = localStorage.getItem("sameEngList");
        korData.forEach((idx, key) => {
          const list = {
            time: Number(idx[0]),
            toward:
              JSON.parse(localKorListData).includes(key) === true
                ? key + 1
                : undefined,
            text: idx[2],
          };
          korListData.push(list);
        });
        smiDatas.koreaSmi = korListData;
        const engTimeList = [];
        engData.forEach((idx, key) => {
          const toward = JSON.parse(localEngListData).includes(key)
            ? key + 1
            : undefined;
          const list = {
            time: Number(idx[0]),
            toward: toward,
            text: idx[1],
          };
          engListData.push(list);
          engTimeList.push(Number(idx[0]));
        });
        smiDatas.englishSmi = engListData;
        hotKor.updateSettings({
          cells: function (row, col) {
            let cellProperties = {};
            cellProperties.renderer = function (
              instance,
              td,
              cellrow,
              cellcol,
              prop,
              value,
              cellProperties
            ) {
              Handsontable.renderers.TextRenderer.apply(this, arguments);
              if (
                hotKor.getDataAtCell(cellrow, 0) ===
                hotEng.getDataAtCell(cellrow, 0)
              ) {
                td.style.background = "rgba(41, 155, 62, 0.1)";

                if (
                  hotKor.getDataAtCell(cellrow, 2) === undefined ||
                  hotEng.getDataAtCell(cellrow, 1) === undefined ||
                  hotKor.getDataAtCell(cellrow, 2) === "" ||
                  hotEng.getDataAtCell(cellrow, 1) === "" ||
                  hotKor.getDataAtCell(cellrow, 2) === null ||
                  hotEng.getDataAtCell(cellrow, 1) === null
                ) {
                  td.style.background = "#ffffff";
                }
                for (let i = 0; i < korListData.length; i++) {
                  const sameTimeIndex = engTimeList.indexOf(
                    korListData[i].time
                  );
                  if (sameTimeIndex !== -1 && i === cellrow && col === 1) {
                    td.innerHTML = sameTimeIndex + 1;
                    if (
                      korTableRef.current.hotInstance.getDataAtCell(
                        cellrow,
                        2
                      ) === undefined ||
                      korTableRef.current.hotInstance.getDataAtCell(
                        cellrow,
                        2
                      ) === ""
                    ) {
                      td.innerHTML = "";
                    }
                    if (
                      engTableRef.current.hotInstance.getDataAtCell(
                        cellrow,
                        1
                      ) === "" ||
                      engTableRef.current.hotInstance.getDataAtCell(
                        cellrow,
                        1
                      ) === undefined
                    ) {
                      td.innerHTML = "";
                    }
                  }
                }
              }
            };
            return cellProperties;
          },
        });

        hotEng.updateSettings({
          cells: function (row, col) {
            let cellProperties = {};

            cellProperties.renderer = function (
              instance,
              td,
              cellrow,
              cellcol,
              prop,
              value,
              cellProperties
            ) {
              Handsontable.renderers.TextRenderer.apply(this, arguments);
              if (
                hotKor.getDataAtCell(cellrow, 0) ===
                hotEng.getDataAtCell(cellrow, 0)
              ) {
                td.style.background = "rgba(41, 155, 62, 0.1)";

                if (
                  hotKor.getDataAtCell(cellrow, 2) === undefined ||
                  hotEng.getDataAtCell(cellrow, 1) === undefined ||
                  hotKor.getDataAtCell(cellrow, 2) === "" ||
                  hotEng.getDataAtCell(cellrow, 1) === "" ||
                  hotKor.getDataAtCell(cellrow, 2) === null ||
                  hotEng.getDataAtCell(cellrow, 1) === null
                ) {
                  td.style.background = "#ffffff";
                }
              }
            };
            return cellProperties;
          },
        });
      }
    },
    afterRemoveRow: function (e) {
      if (korTableRef.current === null) return;

      if (smiDatas.koreaSmi === fakeData || smiDatas.englishSmi === fakeData)
        return;

      const length = korTableRef.current.props.settings.data.length;
      const smiData = korTableRef.current.hotInstance.getData(length, 2);
      const dataList = [];
      smiData.forEach((idx, key) => {
        const list = {
          time: idx[0],
          text: idx[2],
        };
        dataList.push(list);
      });
      smiDatas.koreaSmi = dataList;
      if (!isSetList) {
        onReturnSameData();
      }
      return true;
    },
    beforeKeyDown: function (e) {
      if (e.key === "d" && e.ctrlKey) {
        console.log("누름");

        e.preventDefault();
        this.alter("remove_row", selectRow.number);
        const hotKor = korTableRef.current.hotInstance;
        const hotEng = engTableRef.current.hotInstance;
        const korData = korTableRef.current.hotInstance.getData(
          korTableData.length,
          2
        );
        const engData = engTableRef.current.hotInstance.getData(
          engTableData.length,
          1
        );
        const korListData = [];
        const engListData = [];
        const localKorListData = localStorage.getItem("sameKorList");
        const localEngListData = localStorage.getItem("sameEngList");
        korData.forEach((idx, key) => {
          const list = {
            time: Number(idx[0]),
            toward:
              JSON.parse(localKorListData).includes(key) === true
                ? key + 1
                : undefined,
            text: idx[2],
          };
          korListData.push(list);
        });
        smiDatas.koreaSmi = korListData;
        const engTimeList = [];
        engData.forEach((idx, key) => {
          const toward = JSON.parse(localEngListData).includes(key)
            ? key + 1
            : undefined;
          const list = {
            time: Number(idx[0]),
            toward: toward,
            text: idx[1],
          };
          engListData.push(list);
          engTimeList.push(Number(idx[0]));
        });
        smiDatas.englishSmi = engListData;
        hotKor.updateSettings({
          cells: function (row, col) {
            let cellProperties = {};
            cellProperties.renderer = function (
              instance,
              td,
              cellrow,
              cellcol,
              prop,
              value,
              cellProperties
            ) {
              Handsontable.renderers.TextRenderer.apply(this, arguments);
              if (
                hotKor.getDataAtCell(cellrow, 0) ===
                hotEng.getDataAtCell(cellrow, 0)
              ) {
                td.style.background = "rgba(41, 155, 62, 0.1)";
                if (
                  hotKor.getDataAtCell(cellrow, 2) === undefined ||
                  hotEng.getDataAtCell(cellrow, 1) === undefined ||
                  hotKor.getDataAtCell(cellrow, 2) === "" ||
                  hotEng.getDataAtCell(cellrow, 1) === "" ||
                  hotKor.getDataAtCell(cellrow, 2) === null ||
                  hotEng.getDataAtCell(cellrow, 1) === null
                ) {
                  td.style.background = "#ffffff";
                }
                for (let i = 0; i < korListData.length; i++) {
                  const sameTimeIndex = engTimeList.indexOf(
                    korListData[i].time
                  );
                  if (sameTimeIndex !== -1 && i === cellrow && col === 1) {
                    td.innerHTML = sameTimeIndex + 1;
                    if (
                      korTableRef.current.hotInstance.getDataAtCell(
                        cellrow,
                        2
                      ) === undefined ||
                      korTableRef.current.hotInstance.getDataAtCell(
                        cellrow,
                        2
                      ) === ""
                    ) {
                      td.innerHTML = "";
                    }
                    if (
                      engTableRef.current.hotInstance.getDataAtCell(
                        cellrow,
                        1
                      ) === "" ||
                      engTableRef.current.hotInstance.getDataAtCell(
                        cellrow,
                        1
                      ) === undefined
                    ) {
                      td.innerHTML = "";
                    }
                  }
                }
              }
            };
            return cellProperties;
          },
        });
        hotEng.updateSettings({
          cells: function (row, col) {
            let cellProperties = {};
            cellProperties.renderer = function (
              instance,
              td,
              cellrow,
              cellcol,
              prop,
              value,
              cellProperties
            ) {
              Handsontable.renderers.TextRenderer.apply(this, arguments);
              if (
                hotKor.getDataAtCell(cellrow, 0) ===
                hotEng.getDataAtCell(cellrow, 0)
              ) {
                td.style.background = "rgba(41, 155, 62, 0.1)";
                if (
                  hotKor.getDataAtCell(cellrow, 2) === undefined ||
                  hotEng.getDataAtCell(cellrow, 1) === undefined ||
                  hotKor.getDataAtCell(cellrow, 2) === "" ||
                  hotEng.getDataAtCell(cellrow, 1) === "" ||
                  hotKor.getDataAtCell(cellrow, 2) === null ||
                  hotEng.getDataAtCell(cellrow, 1) === null
                ) {
                  td.style.background = "#ffffff";
                }
              }
            };
            return cellProperties;
          },
        });
      }
    },
    beforeCopy: function () {
      // onReturnSameData();
    },
    afterGetColHeader: function (col, TH) {
      TH.className = "htMiddle";
    },
    afterGetRowHeader: function (row, TH) {
      TH.className = "htMiddle";
    },
    afterRender: false,
    preventOverflow: "vertical",
    afterSelection: (
      row,
      column,
      row2,
      column2,
      preventScrolling,
      selectionLayerLevel
    ) => {
      selectRow = {
        type: "eng",
        number: row,
      };
    },
  };

  const engSettings = {
    data: engTableData === "" ? fakeData : engTableData,
    colHeaders: ["TIME", "자막 내용"],
    columns: [{ data: "time", type: "numeric" }, { data: "text" }],
    colWidths: [90, 538],
    columnHeaderHeight: 64,
    rowHeaders: true,
    rowHeights: 64,
    manualRowMove: true,
    readOnly: false,
    rowHeaderWidth: 30,
    rowWidths: 10,
    bindRowsWithHeaders: false,
    licenseKey: "non-commercial-and-evaluation",
    manualRowResize: true,
    className: "htCenter htMiddle",
    contextMenu: !isSetList
      ? {
          items: {
            copy: {
              name: "복사하기",
            },
            row_below: {
              name: "아래에 줄 추가",
              callback: function (e, r, t) {
                const row = r[0].start.row;
                const col = r[0].start.col;

                const hotEng = engTableRef.current.hotInstance;
                hotEng.alter("insert_row", row + 1);
                onReturnSameData();
                hotEng.setDataAtCell(row + 1, 1, undefined);
                hotEng.setDataAtCell(row + 1, 2, undefined);
              },
            },
            remove_row: {
              name: "줄 삭제",
            },
            undo: {
              name: "되돌리기",
            },
          },
        }
      : {
          items: {
            copy: {
              name: "복사하기",
            },
            row_below: {
              name: "아래에 줄 추가",
              callback: function (e, r, t) {
                const row = r[0].start.row;
                const hotKor = korTableRef.current.hotInstance;
                const hotEng = engTableRef.current.hotInstance;
                hotEng.alter("insert_row", row + 1);
                const korData = korTableRef.current.hotInstance.getData(
                  korTableData.length,
                  2
                );
                const engData = engTableRef.current.hotInstance.getData(
                  engTableData.length,
                  1
                );
                const korListData = [];
                const engListData = [];
                const localKorListData = localStorage.getItem("sameKorList");
                const localEngListData = localStorage.getItem("sameEngList");
                korData.forEach((idx, key) => {
                  const list = {
                    time: Number(idx[0]),
                    toward:
                      JSON.parse(localKorListData).includes(key) === true
                        ? key + 1
                        : undefined,
                    text: idx[2],
                  };
                  korListData.push(list);
                });
                smiDatas.koreaSmi = korListData;
                const engTimeList = [];
                engData.forEach((idx, key) => {
                  const toward = JSON.parse(localEngListData).includes(key)
                    ? key + 1
                    : undefined;
                  const list = {
                    time: Number(idx[0]),
                    toward: toward,
                    text: idx[1],
                  };
                  engListData.push(list);
                  engTimeList.push(Number(idx[0]));
                });
                smiDatas.englishSmi = engListData;
                hotKor.updateSettings({
                  cells: function (row, col) {
                    let cellProperties = {};

                    cellProperties.renderer = function (
                      instance,
                      td,
                      cellrow,
                      cellcol,
                      prop,
                      value,
                      cellProperties
                    ) {
                      Handsontable.renderers.TextRenderer.apply(
                        this,
                        arguments
                      );
                      if (
                        hotKor.getDataAtCell(cellrow, 0) ===
                        hotEng.getDataAtCell(cellrow, 0)
                      ) {
                        td.style.background = "rgba(41, 155, 62, 0.1)";

                        if (
                          hotKor.getDataAtCell(cellrow, 2) === undefined ||
                          hotEng.getDataAtCell(cellrow, 1) === undefined ||
                          hotKor.getDataAtCell(cellrow, 2) === "" ||
                          hotEng.getDataAtCell(cellrow, 1) === "" ||
                          hotKor.getDataAtCell(cellrow, 2) === null ||
                          hotEng.getDataAtCell(cellrow, 1) === null
                        ) {
                          td.style.background = "#ffffff";
                        }
                        for (let i = 0; i < korListData.length; i++) {
                          const sameTimeIndex = engTimeList.indexOf(
                            korListData[i].time
                          );
                          if (
                            sameTimeIndex !== -1 &&
                            i === cellrow &&
                            col === 1
                          ) {
                            td.innerHTML = sameTimeIndex + 1;
                            if (
                              korTableRef.current.hotInstance.getDataAtCell(
                                cellrow,
                                2
                              ) === undefined ||
                              korTableRef.current.hotInstance.getDataAtCell(
                                cellrow,
                                2
                              ) === ""
                            ) {
                              td.innerHTML = "";
                            }
                            if (
                              engTableRef.current.hotInstance.getDataAtCell(
                                cellrow,
                                1
                              ) === "" ||
                              engTableRef.current.hotInstance.getDataAtCell(
                                cellrow,
                                1
                              ) === undefined
                            ) {
                              td.innerHTML = "";
                            }
                          }
                        }
                      }
                    };
                    return cellProperties;
                  },
                });

                hotEng.updateSettings({
                  cells: function (row, col) {
                    let cellProperties = {};

                    cellProperties.renderer = function (
                      instance,
                      td,
                      cellrow,
                      cellcol,
                      prop,
                      value,
                      cellProperties
                    ) {
                      Handsontable.renderers.TextRenderer.apply(
                        this,
                        arguments
                      );
                      if (
                        hotKor.getDataAtCell(cellrow, 0) ===
                        hotEng.getDataAtCell(cellrow, 0)
                      ) {
                        td.style.background = "rgba(41, 155, 62, 0.1)";

                        if (
                          hotKor.getDataAtCell(cellrow, 2) === undefined ||
                          hotEng.getDataAtCell(cellrow, 1) === undefined ||
                          hotKor.getDataAtCell(cellrow, 2) === "" ||
                          hotEng.getDataAtCell(cellrow, 1) === "" ||
                          hotKor.getDataAtCell(cellrow, 2) === null ||
                          hotEng.getDataAtCell(cellrow, 1) === null
                        ) {
                          td.style.background = "#ffffff";
                        }
                      }
                    };
                    return cellProperties;
                  },
                });
              },
            },
            remove_row: {
              name: "줄 삭제",
              callback: function (e, r, t) {
                console.log(r);
                const startRow = r[0].start.row;
                const endRow = r[0].end.row;
                const hotKor = korTableRef.current.hotInstance;
                const hotEng = engTableRef.current.hotInstance;
                startRow === endRow
                  ? hotEng.alter("remove_row", startRow)
                  : hotEng.alter("remove_row", startRow, endRow - startRow + 1);
                const korData = korTableRef.current.hotInstance.getData(
                  korTableData.length,
                  2
                );
                const engData = engTableRef.current.hotInstance.getData(
                  engTableData.length,
                  1
                );
                const korListData = [];
                const engListData = [];
                const localKorListData = localStorage.getItem("sameKorList");
                const localEngListData = localStorage.getItem("sameEngList");
                korData.forEach((idx, key) => {
                  const list = {
                    time: Number(idx[0]),
                    toward:
                      JSON.parse(localKorListData).includes(key) === true
                        ? key + 1
                        : undefined,
                    text: idx[2],
                  };
                  korListData.push(list);
                });
                smiDatas.koreaSmi = korListData;
                const engTimeList = [];
                engData.forEach((idx, key) => {
                  const toward = JSON.parse(localEngListData).includes(key)
                    ? key + 1
                    : undefined;
                  const list = {
                    time: Number(idx[0]),
                    toward: toward,
                    text: idx[1],
                  };
                  engListData.push(list);
                  engTimeList.push(Number(idx[0]));
                });
                smiDatas.englishSmi = engListData;
                hotKor.updateSettings({
                  cells: function (row, col) {
                    let cellProperties = {};
                    cellProperties.renderer = function (
                      instance,
                      td,
                      cellrow,
                      cellcol,
                      prop,
                      value,
                      cellProperties
                    ) {
                      Handsontable.renderers.TextRenderer.apply(
                        this,
                        arguments
                      );
                      if (
                        hotKor.getDataAtCell(cellrow, 0) ===
                        hotEng.getDataAtCell(cellrow, 0)
                      ) {
                        td.style.background = "rgba(41, 155, 62, 0.1)";
                        if (
                          hotKor.getDataAtCell(cellrow, 2) === undefined ||
                          hotEng.getDataAtCell(cellrow, 1) === undefined ||
                          hotKor.getDataAtCell(cellrow, 2) === "" ||
                          hotEng.getDataAtCell(cellrow, 1) === "" ||
                          hotKor.getDataAtCell(cellrow, 2) === null ||
                          hotEng.getDataAtCell(cellrow, 1) === null
                        ) {
                          td.style.background = "#ffffff";
                        }
                        for (let i = 0; i < korListData.length; i++) {
                          const sameTimeIndex = engTimeList.indexOf(
                            korListData[i].time
                          );
                          if (
                            sameTimeIndex !== -1 &&
                            i === cellrow &&
                            col === 1
                          ) {
                            td.innerHTML = sameTimeIndex + 1;
                            if (
                              korTableRef.current.hotInstance.getDataAtCell(
                                cellrow,
                                2
                              ) === undefined ||
                              korTableRef.current.hotInstance.getDataAtCell(
                                cellrow,
                                2
                              ) === ""
                            ) {
                              td.innerHTML = "";
                            }
                            if (
                              engTableRef.current.hotInstance.getDataAtCell(
                                cellrow,
                                1
                              ) === "" ||
                              engTableRef.current.hotInstance.getDataAtCell(
                                cellrow,
                                1
                              ) === undefined
                            ) {
                              td.innerHTML = "";
                            }
                          }
                        }
                      }
                    };
                    return cellProperties;
                  },
                });
                hotEng.updateSettings({
                  cells: function (row, col) {
                    let cellProperties = {};
                    cellProperties.renderer = function (
                      instance,
                      td,
                      cellrow,
                      cellcol,
                      prop,
                      value,
                      cellProperties
                    ) {
                      Handsontable.renderers.TextRenderer.apply(
                        this,
                        arguments
                      );
                      if (
                        hotKor.getDataAtCell(cellrow, 0) ===
                        hotEng.getDataAtCell(cellrow, 0)
                      ) {
                        td.style.background = "rgba(41, 155, 62, 0.1)";
                        if (
                          hotKor.getDataAtCell(cellrow, 2) === undefined ||
                          hotEng.getDataAtCell(cellrow, 1) === undefined ||
                          hotKor.getDataAtCell(cellrow, 2) === "" ||
                          hotEng.getDataAtCell(cellrow, 1) === "" ||
                          hotKor.getDataAtCell(cellrow, 2) === null ||
                          hotEng.getDataAtCell(cellrow, 1) === null
                        ) {
                          td.style.background = "#ffffff";
                        }
                      }
                    };
                    return cellProperties;
                  },
                });
              },
            },
            undo: {
              name: "되돌리기",
            },
          },
        },
    undo: true,
    // beforeKeyDown: (e) => {
    //   if (e.ctrlKey && e.key === "d") {
    //     console.log("ㅎㅎ");
    //   }
    // },
    afterRowMove: function (e, r, t, y) {
      if (engTableRef.current === null) return;
      if (smiDatas.koreaSmi === fakeData || smiDatas.englishSmi === fakeData)
        return;

      const length = engTableRef.current.props.settings.data.length;
      const smiData = engTableRef.current.hotInstance.getData(length, 1);
      const dataList = [];
      smiData.forEach((idx, key) => {
        const list = {
          time: idx[0],
          text: idx[1],
        };
        dataList.push(list);
      });
      smiDatas.englishSmi = dataList;
      if (!isSetList) {
        onReturnSameData();
      }
    },
    afterChange: function (e) {
      if (engTableRef.current === null) return;
      if (smiDatas.koreaSmi === fakeData || smiDatas.englishSmi === fakeData)
        return;
      const hotKor = korTableRef.current.hotInstance;
      const hotEng = engTableRef.current.hotInstance;
      console.log(e);
      if (e !== null) {
        if (e[0][3] === "") {
          return;
        }
        if (isNaN(Number(e[0][3])) && e[0][1] === "time") {
          alert("시간값은 숫자만 입력이 가능합니다.");
          return hotEng.setDataAtCell(e[0][0], 0, e[0][2]);
        }
        if (
          Number(e[0][3]) < hotEng.getDataAtCell(e[0][0] - 1, 0) &&
          e[0][1] === "time"
        ) {
          alert("시간 입력값은 이전 시간보다 빠를 수 없습니다.");
          return hotEng.setDataAtCell(e[0][0], 0, "");
        }
        if (
          Number(e[0][3]) > hotEng.getDataAtCell(e[0][0] + 1, 0) &&
          e[0][1] === "time"
        ) {
          alert("시간 입력값은 다음 시간값보다 느릴 수 없습니다.");
          return hotEng.setDataAtCell(e[0][0], 0, "");
        }
      }

      const length = engTableRef.current.props.settings.data.length;
      const smiData = engTableRef.current.hotInstance.getData(length, 1);
      const dataList = [];
      smiData.forEach((idx, key) => {
        const list = {
          time: idx[0],
          text: idx[1],
        };
        dataList.push(list);
      });
      smiDatas.englishSmi = dataList;
      if (!isSetList) {
        onReturnSameData();
      } else {
        const korData = korTableRef.current.hotInstance.getData(
          korTableData.length,
          2
        );
        const engData = engTableRef.current.hotInstance.getData(
          engTableData.length,
          1
        );
        const korListData = [];
        const engListData = [];
        const localKorListData = localStorage.getItem("sameKorList");
        const localEngListData = localStorage.getItem("sameEngList");
        korData.forEach((idx, key) => {
          const list = {
            time: Number(idx[0]),
            toward:
              JSON.parse(localKorListData).includes(key) === true
                ? key + 1
                : undefined,
            text: idx[2],
          };
          korListData.push(list);
        });
        smiDatas.koreaSmi = korListData;
        const engTimeList = [];
        engData.forEach((idx, key) => {
          const toward = JSON.parse(localEngListData).includes(key)
            ? key + 1
            : undefined;
          const list = {
            time: Number(idx[0]),
            toward: toward,
            text: idx[1],
          };
          engListData.push(list);
          engTimeList.push(Number(idx[0]));
        });
        smiDatas.englishSmi = engListData;
        hotKor.updateSettings({
          cells: function (row, col) {
            let cellProperties = {};

            cellProperties.renderer = function (
              instance,
              td,
              cellrow,
              cellcol,
              prop,
              value,
              cellProperties
            ) {
              Handsontable.renderers.TextRenderer.apply(this, arguments);
              if (
                hotKor.getDataAtCell(cellrow, 0) ===
                hotEng.getDataAtCell(cellrow, 0)
              ) {
                td.style.background = "rgba(41, 155, 62, 0.1)";

                if (
                  hotKor.getDataAtCell(cellrow, 2) === undefined ||
                  hotEng.getDataAtCell(cellrow, 1) === undefined ||
                  hotKor.getDataAtCell(cellrow, 2) === "" ||
                  hotEng.getDataAtCell(cellrow, 1) === "" ||
                  hotKor.getDataAtCell(cellrow, 2) === null ||
                  hotEng.getDataAtCell(cellrow, 1) === null
                ) {
                  td.style.background = "#ffffff";
                }
                for (let i = 0; i < korListData.length; i++) {
                  const sameTimeIndex = engTimeList.indexOf(
                    korListData[i].time
                  );
                  if (sameTimeIndex !== -1 && i === cellrow && col === 1) {
                    td.innerHTML = sameTimeIndex + 1;
                    if (
                      korTableRef.current.hotInstance.getDataAtCell(
                        cellrow,
                        2
                      ) === undefined ||
                      korTableRef.current.hotInstance.getDataAtCell(
                        cellrow,
                        2
                      ) === ""
                    ) {
                      td.innerHTML = "";
                    }
                    if (
                      engTableRef.current.hotInstance.getDataAtCell(
                        cellrow,
                        1
                      ) === "" ||
                      engTableRef.current.hotInstance.getDataAtCell(
                        cellrow,
                        1
                      ) === undefined
                    ) {
                      td.innerHTML = "";
                    }
                  }
                }
              }
            };
            return cellProperties;
          },
        });

        hotEng.updateSettings({
          cells: function (row, col) {
            let cellProperties = {};

            cellProperties.renderer = function (
              instance,
              td,
              cellrow,
              cellcol,
              prop,
              value,
              cellProperties
            ) {
              Handsontable.renderers.TextRenderer.apply(this, arguments);
              if (
                hotKor.getDataAtCell(cellrow, 0) ===
                hotEng.getDataAtCell(cellrow, 0)
              ) {
                td.style.background = "rgba(41, 155, 62, 0.1)";

                if (
                  hotKor.getDataAtCell(cellrow, 2) === undefined ||
                  hotEng.getDataAtCell(cellrow, 1) === undefined ||
                  hotKor.getDataAtCell(cellrow, 2) === "" ||
                  hotEng.getDataAtCell(cellrow, 1) === "" ||
                  hotKor.getDataAtCell(cellrow, 2) === null ||
                  hotEng.getDataAtCell(cellrow, 1) === null
                ) {
                  td.style.background = "#ffffff";
                }
              }
            };
            return cellProperties;
          },
        });
      }
    },
    afterRemoveRow: function (e) {
      if (engTableRef.current === null) return;

      if (smiDatas.koreaSmi === fakeData || smiDatas.englishSmi === fakeData)
        return;

      const length = engTableRef.current.props.settings.data.length;
      const smiData = engTableRef.current.hotInstance.getData(length, 1);
      const dataList = [];
      smiData.forEach((idx, key) => {
        const list = {
          time: idx[0],
          text: idx[1],
        };
        dataList.push(list);
      });
      smiDatas.englishSmi = dataList;
      if (!isSetList) {
        onReturnSameData();
      }
      return true;
    },
    afterGetColHeader: function (col, TH) {
      TH.className = "htMiddle";
    },
    beforeCopy: function () {
      // onReturnSameData();
    },
    afterGetRowHeader: function (row, TH) {
      TH.className = "htMiddle";
    },
    afterUndo: function (e) {
      console.log(e);
    },
    preventOverflow: "vertical",
    beforeKeyDown: function (e) {
      if (e.ctrlKey && e.key === "d") {
        e.preventDefault();
        const hotKor = korTableRef.current.hotInstance;
        const hotEng = engTableRef.current.hotInstance;
        this.alter("remove_row", selectRow.number);
        const korData = korTableRef.current.hotInstance.getData(
          korTableData.length,
          2
        );
        const engData = engTableRef.current.hotInstance.getData(
          engTableData.length,
          1
        );
        const korListData = [];
        const engListData = [];
        const localKorListData = localStorage.getItem("sameKorList");
        const localEngListData = localStorage.getItem("sameEngList");
        korData.forEach((idx, key) => {
          const list = {
            time: Number(idx[0]),
            toward:
              JSON.parse(localKorListData).includes(key) === true
                ? key + 1
                : undefined,
            text: idx[2],
          };
          korListData.push(list);
        });
        smiDatas.koreaSmi = korListData;
        const engTimeList = [];
        engData.forEach((idx, key) => {
          const toward = JSON.parse(localEngListData).includes(key)
            ? key + 1
            : undefined;
          const list = {
            time: Number(idx[0]),
            toward: toward,
            text: idx[1],
          };
          engListData.push(list);
          engTimeList.push(Number(idx[0]));
        });
        smiDatas.englishSmi = engListData;
        hotKor.updateSettings({
          cells: function (row, col) {
            let cellProperties = {};
            cellProperties.renderer = function (
              instance,
              td,
              cellrow,
              cellcol,
              prop,
              value,
              cellProperties
            ) {
              Handsontable.renderers.TextRenderer.apply(this, arguments);
              if (
                hotKor.getDataAtCell(cellrow, 0) ===
                hotEng.getDataAtCell(cellrow, 0)
              ) {
                td.style.background = "rgba(41, 155, 62, 0.1)";
                if (
                  hotKor.getDataAtCell(cellrow, 2) === undefined ||
                  hotEng.getDataAtCell(cellrow, 1) === undefined ||
                  hotKor.getDataAtCell(cellrow, 2) === "" ||
                  hotEng.getDataAtCell(cellrow, 1) === "" ||
                  hotKor.getDataAtCell(cellrow, 2) === null ||
                  hotEng.getDataAtCell(cellrow, 1) === null
                ) {
                  td.style.background = "#ffffff";
                }
                for (let i = 0; i < korListData.length; i++) {
                  const sameTimeIndex = engTimeList.indexOf(
                    korListData[i].time
                  );
                  if (sameTimeIndex !== -1 && i === cellrow && col === 1) {
                    td.innerHTML = sameTimeIndex + 1;
                    if (
                      korTableRef.current.hotInstance.getDataAtCell(
                        cellrow,
                        2
                      ) === undefined ||
                      korTableRef.current.hotInstance.getDataAtCell(
                        cellrow,
                        2
                      ) === ""
                    ) {
                      td.innerHTML = "";
                    }
                    if (
                      engTableRef.current.hotInstance.getDataAtCell(
                        cellrow,
                        1
                      ) === "" ||
                      engTableRef.current.hotInstance.getDataAtCell(
                        cellrow,
                        1
                      ) === undefined
                    ) {
                      td.innerHTML = "";
                    }
                  }
                }
              }
            };
            return cellProperties;
          },
        });
        hotEng.updateSettings({
          cells: function (row, col) {
            let cellProperties = {};
            cellProperties.renderer = function (
              instance,
              td,
              cellrow,
              cellcol,
              prop,
              value,
              cellProperties
            ) {
              Handsontable.renderers.TextRenderer.apply(this, arguments);
              if (
                hotKor.getDataAtCell(cellrow, 0) ===
                hotEng.getDataAtCell(cellrow, 0)
              ) {
                td.style.background = "rgba(41, 155, 62, 0.1)";
                if (
                  hotKor.getDataAtCell(cellrow, 2) === undefined ||
                  hotEng.getDataAtCell(cellrow, 1) === undefined ||
                  hotKor.getDataAtCell(cellrow, 2) === "" ||
                  hotEng.getDataAtCell(cellrow, 1) === "" ||
                  hotKor.getDataAtCell(cellrow, 2) === null ||
                  hotEng.getDataAtCell(cellrow, 1) === null
                ) {
                  td.style.background = "#ffffff";
                }
              }
            };
            return cellProperties;
          },
        });
      }
    },
    afterSelection: (
      row,
      column,
      row2,
      column2,
      preventScrolling,
      selectionLayerLevel
    ) => {
      selectRow = {
        type: "eng",
        number: row,
      };
    },
    // afterScrollVertically: function (e) {
    //   const autoRowSize =
    //     engTableRef.current.hotInstance.getPlugin("AutoRowSize");
    //   if (isSyncScroll) {
    //     const engTableCurrentRow = autoRowSize.getFirstVisibleRow();
    //     // console.log(engTableCurrentRow);
    //     korTableRef.current.hotInstance.scrollViewportTo(
    //       engTableCurrentRow - 1
    //     );
    //   }
    // },
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <GlobalStyle />
          <Container>
            <TitleContainer>
              <div>
                <Button
                  name="목록보기"
                  size="small"
                  image={"back"}
                  width="120.2"
                  onClick={onGoBackClick}
                />
              </div>
              <div>
                <Input
                  placeholder="제목을 입력해주세요."
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                />
              </div>
              <div>
                <Button
                  name="작업 전체 삭제"
                  size="small"
                  width="128"
                  onClick={() => onDeleteClick(id)}
                />
              </div>
            </TitleContainer>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {/* 한글 자막 컨테이너 */}
              <SmiContainer>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "668px",
                  }}
                >
                  <div>
                    <Title>
                      {"한글 자막"} ({korTableData.length})
                    </Title>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <FileName value={koreaFileName} disabled={true} />
                    {korTableData === "" ? (
                      <>
                        <UploadButton htmlFor={"upload"}>
                          파일 올리기
                        </UploadButton>
                        <UploadInput
                          type="file"
                          id={"upload"}
                          name="file"
                          onChange={onKorSandFile}
                        />
                      </>
                    ) : (
                      <UploadButton
                        onClick={() => {
                          korTableRef.current.hotInstance.updateSettings({
                            data: [
                              { time: undefined, text: undefined },
                              { time: undefined, text: undefined },
                              { time: undefined, text: undefined },
                              { time: undefined, text: undefined },
                              { time: undefined, text: undefined },
                              { time: undefined, text: undefined },
                              { time: undefined, text: undefined },
                              { time: undefined, text: undefined },
                            ],
                          });
                          smiDatas.koreaSmi = null;
                          setKorTableData("");
                          setKoreaFileName("파일명");
                          updateFileName(null, "kor");
                          localStorage.setItem("sameKorList", "[]");
                          smiDatas.smiList = [];
                          setIsSetList(false);
                        }}
                      >
                        파일 제거하기
                      </UploadButton>
                    )}
                  </div>
                </div>
                <HandsTable
                  id="korTable"
                  settings={korSettings}
                  ref={korTableRef}
                  type="kor"
                />

                {/* <Styled>
                  <HotTable ref={korTableRef} settings={korSettings}>
                    <HotColumn data="time" color="color">
                      <IdRenderer hot-renderer samelist={null} />
                    </HotColumn>
                    <HotColumn data="text" color="color">
                      <TextRenderer hot-renderer samelist={null} />
                    </HotColumn>
                  </HotTable>
                </Styled> */}
              </SmiContainer>
              {/* 영어자막 컨테이너 */}
              <SmiContainer>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "668px",
                  }}
                >
                  <div>
                    <Title>
                      {"영어자막"} ({engTableData.length})
                    </Title>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <FileName value={otherFileName} disabled={true} />
                    {engTableData === "" ? (
                      <>
                        <UploadButton htmlFor={"foreUpload"}>
                          파일 올리기
                        </UploadButton>
                        <UploadInput
                          type="file"
                          id={"foreUpload"}
                          name="file"
                          onChange={onEngSandFile}
                        />
                      </>
                    ) : (
                      <UploadButton
                        onClick={() => {
                          engTableRef.current.hotInstance.updateSettings({
                            data: [
                              { time: undefined, text: undefined },
                              { time: undefined, text: undefined },
                              { time: undefined, text: undefined },
                              { time: undefined, text: undefined },
                              { time: undefined, text: undefined },
                              { time: undefined, text: undefined },
                              { time: undefined, text: undefined },
                              { time: undefined, text: undefined },
                            ],
                          });
                          smiDatas.englishSmi = null;
                          setEngTableData("");
                          setOtherFileName("파일명");
                          updateFileName(null, "eng");
                          localStorage.setItem("sameEngList", "[]");
                          smiDatas.smiList = [];
                          setIsSetList(false);
                        }}
                      >
                        파일 제거하기
                      </UploadButton>
                    )}
                  </div>
                </div>
                <HandsTable
                  id="engTable"
                  settings={engSettings}
                  ref={engTableRef}
                  type="eng"
                />
                {/* <Styled>
                  <HotTable ref={engTableRef} settings={engSettings}>
                    <HotColumn data="time" color="color">
                      <IdRenderer hot-renderer samelist={null} />
                    </HotColumn>
                    <HotColumn data="text" color="color">
                      <TextRenderer hot-renderer samelist={null} />
                    </HotColumn>
                  </HotTable>
                </Styled> */}
              </SmiContainer>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "64px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ marginLeft: "32.5px" }}>
                  <Button
                    name="자막 정렬하기"
                    color="#881424"
                    size="middle"
                    width="257"
                    onClick={() => setSmiList()}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginRight: "32.5px",
                }}
              >
                <SelectBox
                  type="checkbox"
                  value={isChecked || undefined}
                  checked={isChecked ? "checked" : null}
                  onChange={() => setIsChecked(!isChecked)}
                  id="write"
                />
                <SelectText htmlFor="write">작성완료</SelectText>
                <SelectBox
                  type="checkbox"
                  value={isSyncScroll || ""}
                  checked={isSyncScroll ? "checked" : null}
                  onChange={() => {
                    setIsSyncScroll(isSyncScroll ? false : true);
                  }}
                  id="syncScroll"
                  style={{ marginLeft: "30px" }}
                />
                <SelectText htmlFor="syncScroll">동시 스크롤</SelectText>
                <div
                  style={{
                    marginRight: "32.5px",
                    marginLeft: "32.5px",
                  }}
                >
                  <Button
                    name="저장하기"
                    color="#299b3e"
                    size="middle"
                    width="257"
                    onClick={() => onClick()}
                    image="save"
                  />
                </div>
                <div>
                  <Button
                    name="엑셀로 내보내기"
                    size="middle"
                    width="280"
                    onClick={() => onExcelClick()}
                  />
                </div>
              </div>
            </div>
          </Container>
        </>
      )}
    </>
  );
}

const Container = styled.div`
  background: #ffffff;
  border-radius: 3px;
  padding: 32px;
  width: 90vw;
  max-width: 1560px;
  min-width: 1280px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  width: auto;
`;

const Input = styled.input`
  width: 1184px;
  height: 43px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 34.75px;
  ::placeholder {
    color: #91969a;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.17px;
  }
`;

const SelectText = styled.label`
  font-weight: 500;
  font-size: 16px;
  line-height: 23.17px;
  color: #002262;
  margin-left: 8px;
`;
const SelectBox = styled.input`
  :checked {
    border: 1px solid #002262;
    color: #002262;
  }
`;

const SmiContainer = styled.div`
  border: 1px solid #d9d9d9;
  padding-top: 32px;
  padding-bottom: 32px;
  width: 732px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  color: #002262;
`;

const FileName = styled.input`
  width: 351px;
  height: 39px;
  border: 1px solid #d9d9d9;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  text-indent: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.17px;
  display: flex;
  align-items: center;
  :disabled {
    background: #ffffff;
  }
  ::placeholder {
    color: #91969a;
  }
`;
const UploadInput = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;
const UploadButton = styled.label`
  display: flex;
  width: 110px;
  height: 43px;
  background: #002262;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  :hover {
    cursor: pointer;
  }
`;

const Styled = styled.div`
  margin-top: 32px;
  height: 580px;
  width: 94%;
  overflow : scroll;

  &::-webkit-scrollbar {
    height: 0px;
    width: 0px;
  /* .handsontable thead {
    align-items: center;
    padding-top: 32px;
  } */

  td,
  th {
    font-size: 18px;
    color: #333333;
    font-weight: 400;
    line-height: 26.06px;
    background: #333333;
    min-height: 64px;
    height : auto;
  };
`;
