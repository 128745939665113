import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadTableData,
  updateTableData,
  updataTableTextData,
  setFileName,
  sameListUpdate,
} from "../slices/tableSlice";
export default function useTableData() {
  const dispatch = useDispatch();
  const onLoadTable = (data, type) => {
    dispatch(
      loadTableData({
        data: data.data,
        type: type,
      })
    );
  };
  const onUpdateTable = (data, type) => {
    dispatch(
      updateTableData({
        data: data,
        type: type,
      })
    );
  };
  const onUpdataTableTextData = (data, type) => {
    dispatch(
      updataTableTextData({
        data: data,
        type: type,
      })
    );
  };

  const updateFileName = (data, type) => {
    dispatch(
      setFileName({
        data: data,
        type: type,
      })
    );
  };
  const onSameList = (data) => {
    dispatch(sameListUpdate(data));
  };
  const korData = useSelector((store) => store.table.korData);
  const engData = useSelector((store) => store.table.engData);
  const korFileName = useSelector((store) => store.table.korFileName);
  const engFileName = useSelector((store) => store.table.engFileName);
  const sameList = useSelector((store) => store.table.sameList);

  return {
    onLoadTable,
    korData,
    engData,
    updateFileName,
    onUpdateTable,
    engFileName,
    korFileName,
    onUpdataTableTextData,
    onSameList,
    sameList,
  };
}
