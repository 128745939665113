import styled, { createGlobalStyle } from "styled-components";
import Table from "../components/Table";
import Button from "../components/Button";
import Search from "../shared/Search.png";
import Loading from "../components/Loading";
import { useCallback, useEffect, useState } from "react";
import useGetList from "../hooks/useGetList";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import useTableData from "../hooks/useTableData";
import TableBody from "../components/TableBody";

const GlobalStyle = createGlobalStyle`
    body {
        display: flex;
        background: #e5e5e5;
        justify-content: center;
        align-items: center;
        height : 100vh;
        width : 100vw;
    }
`;

const Data = [
  {
    id: 1,
    title: "영화제목 1",
    date: "22/01/19",
    language: "En/Ko",
    complete: "진행 중",
    amount: 2,
    manager: "이명섭",
  },
  {
    id: 2,
    title: "영화제목 2",
    date: "22/01/19",
    language: "En/Ko",
    complete: "진행 중",
    amount: 2,
    manager: "이명섭",
  },
  {
    id: 3,
    title: "영화제목 3",
    date: "22/01/19",
    language: "En/Ko",
    complete: "진행 중",
    amount: 2,
    manager: "이명섭",
  },
  {
    id: 4,
    title: "영화제목 4",
    date: "22/01/19",
    language: "En/Ko",
    complete: "진행 중",
    amount: 2,
    manager: "이명섭",
  },
  {
    id: 5,
    title: "영화제목 5",
    date: "22/01/19",
    language: "En/Ko",
    complete: "진행 중",
    amount: 2,
    manager: "이명섭",
  },
  {
    id: 6,
    title: "영화제목 1",
    date: "22/01/19",
    language: "En/Ko",
    complete: "진행 중",
    amount: 2,
    manager: "이명섭",
  },
  {
    id: 7,
    title: "영화제목 2",
    date: "22/01/19",
    language: "En/Ko",
    complete: "진행 중",
    amount: 2,
    manager: "이명섭",
  },
  // {
  //   id: 8,
  //   title: "영화제목 3",
  //   date: "22/01/19",
  //   language: "En/Ko",
  //   complete: "진행 중",
  //   amount: 2,
  //   manager: "이명섭",
  // },
  // {
  //   id: 9,
  //   title: "영화제목 4",
  //   date: "22/01/19",
  //   language: "En/Ko",
  //   complete: "진행 중",
  //   amount: 2,
  //   manager: "이명섭",
  // },
  // {
  //   id: 10,
  //   title: "영화제목 5",
  //   date: "22/01/19",
  //   language: "En/Ko",
  //   complete: "진행 중",
  //   amount: 2,
  //   manager: "이명섭",
  // },
  // {
  //   id: 11,
  //   title: "영화제목 1",
  //   date: "22/01/19",
  //   language: "En/Ko",
  //   complete: "진행 중",
  //   amount: 2,
  //   manager: "이명섭",
  // },
  // {
  //   id: 12,
  //   title: "영화제목 2",
  //   date: "22/01/19",
  //   language: "En/Ko",
  //   complete: "진행 중",
  //   amount: 2,
  //   manager: "이명섭",
  // },
  // {
  //   id: 13,
  //   title: "영화제목 3",
  //   date: "22/01/19",
  //   language: "En/Ko",
  //   complete: "진행 중",
  //   amount: 2,
  //   manager: "이명섭",
  // },
  // {
  //   id: 14,
  //   title: "영화제목 4",
  //   date: "22/01/19",
  //   language: "En/Ko",
  //   complete: "진행 중",
  //   amount: 2,
  //   manager: "이명섭",
  // },
  // {
  //   id: 15,
  //   title: "영화제목 5",
  //   date: "22/01/19",
  //   language: "En/Ko",
  //   complete: "진행 중",
  //   amount: 2,
  //   manager: "이명섭",
  // },
];

export default function Main() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [deleteList, setDeleteList] = useState([]);
  const [list, setList] = useState(null);
  const [searchList, setSearchList] = useState("");
  const { korFileName, engFileName, updateFileName } = useTableData();
  useEffect(() => {
    if (korFileName !== null) {
      updateFileName(null, "kor");
    }
    if (engFileName !== null) {
      updateFileName(null, "eng");
    }
    if (!sessionStorage.getItem("Login")) {
      if (window.confirm("로그인이 필요합니다. 로그인 하시겠습니까?")) {
        navigate("/login");
      } else {
        navigate("/login");
      }
    }
    localStorage.setItem("sameKorList", "[]");
    localStorage.setItem("sameEngList", "[]");
  }, []);
  const onSearchClick = async (keyword) => {
    const datas = JSON.stringify({
      type: "title",
      keyword: keyword,
    });
    await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      // url: "http://subtitle.insightvessel.io/backend/search",
      url: "https://ivsmi.tideflo.work/backend/search",
      data: datas,
    })
      .then((res) => {
        setSearchList(res.data.data);
        // navigate(`/main/${keyword}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchList = async () => {
    const response = await axios({
      method: "POST",
      // url: "http://subtitle.insightvessel.io/backend/list",
      url: "https://ivsmi.tideflo.work/backend/list",

      headers: {
        "Content-Type": "application/json",
      },
    });
    try {
      setLoading(true);
      const list = response.data.data;
      setList(list);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchList();
  }, []);
  const onAllCheckBoxChange = useCallback(
    (checked) => {
      console.log(list);
      if (checked) {
        const checkedList = [];
        list.forEach((list) => checkedList.push(list.id));
        setDeleteList(checkedList);
        console.log(checkedList);
      } else {
        setDeleteList([]);
        console.log(deleteList);
      }
      console.log(deleteList);
    },
    [list]
  );

  const onCheckedElement = useCallback(
    (checked, list) => {
      if (checked) {
        setDeleteList([...deleteList, list]);
      } else {
        setDeleteList(deleteList.filter((el) => el !== list));
      }
    },
    [deleteList]
  );

  const fetchDeleteList = async () => {
    if (deleteList.length === 0) {
      alert("삭제할 작업을 선택해주세요.");
      return;
    }
    if (
      window.confirm("작업이 삭제되면 복구가 불가능합니다.\n삭제하시겠습니까?")
    ) {
      const newDeleteList = [];
      deleteList.forEach((idx) => {
        const obj = {
          id: idx,
        };
        newDeleteList.push(obj);
      });
      const datas = JSON.stringify({
        deletion: newDeleteList,
      });
      console.log(datas);
      await axios({
        method: "post",
        // url: "http://subtitle.insightvessel.io/backend/list_deletion",
        url: "https://ivsmi.tideflo.work/backend/list_deletion",
        headers: {
          "Content-Type": "application/json",
        },
        data: datas,
      })
        .then((res) => {
          if (res.data.code === "200") {
            alert("삭제가 완료되었습니다.");
            window.location.reload();
          }
          return res.data;
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <GlobalStyle />
          <Container>
            <TitleBox>
              <div>
                <Title>자막 리스트</Title>
              </div>
              <RightContents>
                <Selcetbox>
                  <option value="제목">제목</option>
                </Selcetbox>
                <InputBox>
                  <Input
                    placeholder="검색어를 입력하세요"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key == "Enter") {
                        onSearchClick(keyword);
                      }
                    }}
                  />
                  <IconBox onClick={() => onSearchClick(keyword)}>
                    <Icon src={Search} />
                  </IconBox>
                </InputBox>
              </RightContents>
            </TitleBox>
            <Styled>
              {list === null ? (
                <NonData>작업 중인 데이터가 없습니다.</NonData>
              ) : (
                <table>
                  <thead>
                    <Tr>
                      <Checkbox>
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            onAllCheckBoxChange(e.target.checked)
                          }
                          checked={
                            deleteList.length === 0
                              ? false
                              : deleteList.length === list.length
                              ? true
                              : false
                          }
                        />
                      </Checkbox>
                      <No>NO</No>
                      <SmiTitle>제목</SmiTitle>
                      <Date>작성일</Date>
                      <Language>언어</Language>
                      <Complete>진행도</Complete>
                    </Tr>
                  </thead>
                  <tbody>
                    {searchList === ""
                      ? list.map((item, idx) => {
                          return (
                            <tr key={idx}>
                              <th>
                                <input
                                  type={"checkbox"}
                                  onChange={(e) =>
                                    onCheckedElement(e.target.checked, item.id)
                                  }
                                  checked={
                                    deleteList.includes(item.id) ? true : false
                                  }
                                />
                              </th>
                              <th>{idx + 1}</th>
                              <Th
                                style={{
                                  textAlign: "left",
                                  paddingLeft: "32px",
                                }}
                                onClick={() => navigate(`/smi/${item.id}`)}
                              >
                                {item.title}
                              </Th>
                              <th>{item.date}</th>
                              <th>{item.language}</th>
                              <th>{item.complete}</th>
                            </tr>
                          );
                        })
                      : searchList.map((item, idx) => {
                          return (
                            <tr key={idx}>
                              <th>
                                <input
                                  type={"checkbox"}
                                  onChange={(e) =>
                                    onCheckedElement(e.target.checked, item.id)
                                  }
                                  checked={
                                    deleteList.includes(item.id) ? true : false
                                  }
                                />
                              </th>
                              <th>{idx + 1}</th>
                              <Th
                                style={{
                                  textAlign: "left",
                                  paddingLeft: "32px",
                                }}
                                onClick={() => navigate(`/smi/${item.id}`)}
                              >
                                {item.title}
                              </Th>
                              <th>{item.date}</th>
                              <th>{item.language}</th>
                              <th>{item.complete}</th>
                            </tr>
                          );
                        })}
                  </tbody>
                </table>
              )}
            </Styled>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "32px",
              }}
            >
              <Button
                name="작업 삭제하기"
                size="middle"
                width="268"
                onClick={() => fetchDeleteList()}
              />
              <div style={{ width: "60px" }} />
              <Button
                name="새 파일 만들기"
                size="middle"
                width="268"
                onClick={() => navigate("/smi")}
              />
            </div>
          </Container>
          {/* <Loading /> */}
        </>
      )}
    </>
  );
}

const Container = styled.div`
  /* position: fixed; */
  /* top: 10px; */
  background: #ffffff;
  border-radius: 3px;
  padding: 32px;
  width: 90vw;
  max-width: 1560px;
  min-width: 1280px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-height: 720px;
  overflow-y: auto;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

const Title = styled.h1`
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #002262;
`;

const Selcetbox = styled.select`
  width: 110px;
  height: 43px;
  padding: 0px 16px 0px 16px;
  border: 1px solid #d9d9d9;
  margin-right: 16px;
  border-radius: 3px;
`;

const RightContents = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Input = styled.input`
  width: 311px;
  height: 41px;
  padding: 0;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
  text-indent: 16px;
  ::placeholder {
    color: #91969a;
  }
`;

const IconBox = styled.i`
  position: absolute;
  margin-right: 16px;
  :hover {
    cursor: pointer;
  }
`;

const Icon = styled.img`
  width: 19.99px;
  height: 19.99px;
`;

const Styled = styled.div`
  table {
    border: 1px solid #d9d9d9;
    border-collapse: collapse;
    margin-bottom: 32px;
    margin: auto;
    table-layout: fixed;
    width: 100%;
    /* height: 300px; */
  }
  th {
    border: 1px solid #d9d9d9;
    font-size: 18px;
    font-weight: 400;
    line-height: 26.06px;
    padding-top: 19px;
    padding-bottom: 19px;
  }
  max-height: 580px;
  overflow-y: auto;
  /* height: 720px; */
`;

const No = styled.th`
  width: 100px;
`;
const Checkbox = styled.th`
  width: 60px;
`;
const SmiTitle = styled.th`
  width: 697px;
`;
const Date = styled.th`
  width: 200px;
`;
const Language = styled.th`
  width: 199px;
`;
const Complete = styled.th`
  width: 198px;
`;
const Amount = styled.th`
  width: 199px;
`;
const Manager = styled.th`
  width: 198px;
`;
const Tr = styled.tr`
  background: #f6f6f6;
`;
const NonData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
`;
const Th = styled.th`
  :hover {
    cursor: pointer;
  }
`;
