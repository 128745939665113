import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import useGetList from "../hooks/useGetList";
import useSmiList from "../hooks/useSmiList";

const Body = (item, onCheckedElement) => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate(`/smi/${item.id}`);
  };
  return (
    <tr>
      <th>
        <input type={"checkbox"} />
      </th>
      <th>{item.id}</th>
      <Th style={{ textAlign: "left", paddingLeft: "32px" }} onClick={onClick}>
        {item.title}
      </Th>
      <th>{item.date}</th>
      <th>{item.language}</th>
      <th>{item.complete}</th>
    </tr>
  );
};

export default function TableBody({ data }) {
  return (
    <>
      {data.map((item, index) => {
        return (
          <tr>
            <th>
              <input type={"checkbox"} />
            </th>
            <th>{item.id}</th>
            <Th style={{ textAlign: "left", paddingLeft: "32px" }}>
              {item.title}
            </Th>
            <th>{item.date}</th>
            <th>{item.language}</th>
            <th>{item.complete}</th>
          </tr>
        );
      })}
    </>
  );
}

const Th = styled.th`
  :hover {
    cursor: pointer;
  }
`;
const NullData = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
