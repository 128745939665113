import { configureStore, combineReducers } from "@reduxjs/toolkit";
import sandSlice from "./sandSlice";
import smiListSlice from "./smiListSlice";
import tableSlice from "./tableSlice";

const rootReducer = combineReducers({
  sandSmiFile: sandSlice,
  table: tableSlice,
  smiList: smiListSlice,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
