import styled, { createGlobalStyle } from "styled-components";
import LoginBox from "../components/LoginBox";
import Alert from "../components/Alert";

const GlobalStyle = createGlobalStyle`
    body {
        background : #002262
    }
`;

export default function Login() {
  return (
    <>
      <GlobalStyle />
      <LoginContainer>
        <Title>LOGIN</Title>
        <LoginBox />
      </LoginContainer>
    </>
  );
}

const LoginContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: auto;
`;
const Title = styled.h1`
  font-size: 30px;
  font-weight: 500;
  line-height: 43px;
  color: #ffffff;
  margin-bottom: 56px;
  padding: 0px;
`;
