import styled from "styled-components";
import Handsontable from "handsontable";
import { HotTable, HotColumn } from "@handsontable/react";
import "handsontable/dist/handsontable.min.css";
import { registerAllModules } from "handsontable/registry";
import useTableData from "../hooks/useTableData";
import React, { useRef, useState, forwardRef, useEffect, useMemo } from "react";
import "../shared/tableStyle.css";
import { useFilters } from "react-table";

registerAllModules();
let smiDatas = {
  koreaSmi: null,
  englishSmi: null,
  smiList: [],
};

function IdRenderer(props) {
  const localData = localStorage.getItem("sameList");
  if (JSON.parse(localData) !== null) {
    const same = JSON.parse(localData);
    const rows = props.row;
    if (same.findIndex((e) => e === rows) !== -1) {
      props.TD.className = "htMiddle htCenter idBox";
    } else {
      props.TD.className = "htMiddle htCenter";
    }
  } else {
    props.TD.className = "htMiddle htCenter";
  }

  return <div>{props.value}</div>;
}

function TextRenderer(props) {
  const localData = localStorage.getItem("sameList");
  if (JSON.parse(localData) !== null) {
    const same = JSON.parse(localData);
    const rows = props.row;
    if (same.findIndex((e) => e === rows) !== -1) {
      props.TD.className = "htMiddle htCenter idBox";
    } else {
      props.TD.className = "htMiddle htCenter";
    }
  } else {
    props.TD.className = "htMiddle htCenter";
  }

  return <div>{props.value}</div>;
}

function TowardRenderer(props) {
  // console.log(props.value);
  const localData = localStorage.getItem("sameList");
  if (JSON.parse(localData) !== null) {
    const same = JSON.parse(localData);
    const rows = props.row;
    if (same.findIndex((e) => e === rows) !== -1) {
      props.TD.className = "htMiddle htCenter idBox";
    } else {
      props.TD.className = "htMiddle htCenter";
    }
  } else {
    props.TD.className = "htMiddle htCenter";
  }

  return <div>{props.value}</div>;
}

function HandsTable({ tableData, type, settings, id }, ref) {
  return (
    <Styled>
      <HotTable id={id} settings={settings} ref={ref}>
        <HotColumn data="time" color="color">
          <IdRenderer hot-renderer samelist={null} />
        </HotColumn>
        {type === "kor" ? (
          <HotColumn data="toward">
            <TowardRenderer hot-renderer />
          </HotColumn>
        ) : null}
        <HotColumn data="text" color="color">
          <TextRenderer hot-renderer samelist={null} />
        </HotColumn>
      </HotTable>
    </Styled>
  );
}

export default forwardRef(HandsTable);
const CorrectTable = styled.div`
  background: rgba((41, 155, 62, 0.1));
`;
const Styled = styled.div`
  margin-top: 32px;
  height: 580px;
  width: 94%;
  overflow : scroll;

  &::-webkit-scrollbar {
    height: 0px;
    width: 0px;
  /* .handsontable thead {
    align-items: center;
    padding-top: 32px;
  } */

  td,
  th {
    font-size: 18px;
    color: #333333;
    font-weight: 400;
    line-height: 26.06px;
    background: #333333;
    min-height: 64px;
    height : auto;
  };
`;
